/* Blue */
$blue-pale: #dae6f0;
$blue-clair: #4a98d9;
$blue: #1479cc;
$blue-piv: #095797;
$blue-moyen: #19406c;
$blue-fonce: #223654;

/* Yellow/Jaunes */
$jaune-pale: #f8e69a;
$jaune-pale: #e0ad03;
$jaune-pale: #ad781c;

/* Green/Verts */
$vert-pale: #dcf0bb;
$vert: #4f813d;
$vert-fonce: #2c4024;

/* Gray/Gris */
$gris-pale: #f1f1f2;
$gris-clair: #c5cad2;
$gris: #8893a2;
$gris-moyen: #6d778a;
$gris-fonce: #4e5662;
$gris-50: #F8F8F8;

/* Red/Rouges */
$orange-pale: #de724d;
$rose-pale: #ffdbd6;
$rose: #e58271;
$rouge: #cb381f;
$rouge-fonce: #692519;


/* pelican colors */


/* White */
$white: #fff;
$white-dark: #f1f1f1;

/* Grey */
$grey-white: #f1f1f1;
$grey-light: #b7b7b7;
$grey-light-2: #57585a;
$grey-dark: rgba(0, 0, 0, 0.46%);
$grey-for-divider: #1D2739;
$grey-for-benefits:#454545;

/* Black */
$black: #000;
$black-light: #343434;
$black-dark: #191919;

.swiper-pagination {
    margin-top: 40px;
}