.cart-navbar {
  cursor: pointer;
}
#custom-cart {
  cursor: pointer;
}
.cart-sidebar {
  font-family: "Bebas Neue",sans-serif;
  position: fixed;
  padding: 0 48px;
  height: 100%;
  width: 100%;
  max-width: 602px;
  background-color: #fff;
  top: 0;
  right: 0;
  display: inline-flex;
  flex-direction: column;
  flex-shrink: 0;
  border-top: 1px solid var(--Grey-100, #F0F2F5);
  background: var(--Shade-White, #FFF);
  box-shadow: 0px 8px 10px -6px rgba(16, 24, 40, 0.10), 0px 20px 25px -5px rgba(16, 24, 40, 0.10);
  z-index: 2147483647;
  transition: transform 0.3s ease-in-out;
  transform: translateX(100%);
  overflow: scroll;
  @media (max-width: 576px) {
      padding: 16px;
  }
  &.open {
    transform: translateX(0);
  }
  .cart-header {
    position: relative;
    padding: 48px 0;
    z-index: 2147483647;
    background-color: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
    @media (max-width: 576px) {
      padding: 16px;
    }
    .cart-title {
      font-size: 3.4rem;
      text-transform: uppercase;
      line-height: 32px;
      font-weight: 700;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .cart-close {
      width: 32px;
      cursor: pointer;
    }
  }
  .cart-footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    align-self: stretch;
    .cart-checkout-recap {
      display: flex;
      flex-direction: column;
      &__total {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

      }
    }
  }
  .cart-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    hr {
      border-color: #F0F2F5;
      width: 100%;
      margin-top: 26px;
      opacity: 1;
    }
    .cart-checkout-recap {
      width: 100%;
      &__total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 4px;
        align-self: stretch;
        width: 100%;
        p {
          color: var(--Grey-900, #101928);
          font-feature-settings: 'cv04' on, 'cv03' on, 'cv01' on;
          font-family: Inter, serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 19.2px */
          letter-spacing: -0.32px;
        }
      }
    }
    button, a {
      align-self: center;
      width: 100%;
      margin-bottom: 14px;
    }
  }
}

.cart-navbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  .cart-count {
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 14.4px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 10px;
    background: #000;
    position: absolute;
    right: -20px;
    top: -8px;
    text-align: center;
  }

}


