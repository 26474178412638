$grid-gutter-width: 25px; // spacing between grid columns
$grid-columns: 12; // number of grid columns
$grid-max-width: 1200px; // maximum width of the grid

.grid-container {
    margin-right: auto;
    margin-left: auto;
    max-width: $grid-max-width;
    padding-right: calc($grid-gutter-width / 2);
    padding-left: calc($grid-gutter-width / 2);
}

.grid-row {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
}

.grid-col {
    padding-right: calc($grid-gutter-width / 2);
    padding-left: calc($grid-gutter-width / 2);
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

//Define the styles for each column width

@for $i from 1 through $grid-columns {
    .grid-col-#{$i} {
      flex-basis: ( calc(100% / $grid-columns) ) * $i;
      max-width: ( calc(100% / $grid-columns) ) * $i;
    }
}

@media (min-width: 576px) {
  .grid-container {
    padding-right: $grid-gutter-width;
    padding-left: $grid-gutter-width;
  }
}

@media (min-width: 768px) {
  .grid-col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  @for $i from 1 through $grid-columns {
    .grid-col-md-#{$i} {
      flex-basis: (calc(100% / $grid-columns)) * $i;
      max-width: (calc(100% / $grid-columns)) * $i;
    }
  }
}

@media (min-width: 992px) {
  .grid-col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  @for $i from 1 through $grid-columns {
    .grid-col-lg-#{$i} {
      flex-basis: (calc(100% / $grid-columns))  * $i;
      max-width: (calc(100% / $grid-columns))  * $i;
    }
  }
}

@media (min-width: 1200px) {
  .grid-col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  @for $i from 1 through $grid-columns {
    .grid-col-xl-#{$i} {
      flex-basis: (calc(100% / $grid-columns))  * $i;
      max-width: (calc(100% / $grid-columns))  * $i;
    }
  }
}
