.blog-hero-headline {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  & h1 {
    font-size: 5rem;
    line-height: 50px;
    margin-bottom: 20px;
  }
}

/* blogs search section */

.form-control {
  color: $black-light;
  background-color: $white;
  border: none;
  border-bottom: 1px solid $black-light;
  border-radius: 0;
  width: max-content;
  &::-webkit-input-placeholder {
    color: $grey-light;
  }

  &:focus {
    outline-width: 0 !important;
    border: none;
  }
}

h3.section-title {
  font-size: 30px;

  &::after {
    content: " ";
    height: 4px;
    width: 65px;
    background-color: #454545;
    margin-top: 8px;
    display: block;
  }
}

.blog-search-container {
  border-bottom: 1px solid $grey-for-divider;
  padding: 15px 0;

  .blog-search-wrap {
    gap: 25px;
    justify-content: flex-end;

    .blog-search-field {
      position: relative;
    }
    .search-top-title {
      color: $grey-light;
    }
  }
  .bi-sliders2 {
    color: $grey-light-2;
    cursor: pointer;
  }

  .sort-by-label {
    color: #61646b;
  }

  .form-filter-order {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5px;
    width: auto;
    margin: 0;
    & .search-select-order, & .search-select-blog-order{
      font-size: 14px;
      border: 0;
    }
    @include lg {
      margin: auto;
      margin-right: 0;
    }
  }

  @media (max-width: 462px) {
    .wrapper-area {
      flex-direction: column;

      & .form-wrapper {
        width: 80% !important;
        margin-bottom: 10px;
      }
    }
    .form-filter-order {
      margin: 0;
      margin-top: 16px;
      flex-direction: column;
    }
  }

  @media (max-width: 768px) {
    padding: 15px;

    .blog-search-wrap {
      justify-content: space-between;
    }
  }
}

.blog-search-results-container {
  .img-wrapper {
    height: 220px;
    overflow: hidden;
    background-color: #454545;

    img {
      width: 100%;
      height: 220px;
      object-fit: cover;
    }
  }

  .card-text {
    font-size: 14px;
  }

  .card-title {
    font-size: 20px;
  }
}

.search-top-mobile {
  color: $grey-light;
}
.search-top {
  select {
    border: none;
    border-bottom: none;
    margin-right: -15px;
    font-size: initial;
  }
  input {
    border: none;
    border-bottom: none;
    background-color: $white-dark;
    border-radius: 25px;
    padding: 5px 5px 5px 10px;
    display: none;
    transition: all 0.3s ease-in-out;
    font-size: initial;
  }
  .search-top-icon {
    right: 10px;
    top: 8px;
    border: 0;
    background-color: transparent;
  }
}
.blog-left-search-wrap {
  border-right: 1px solid $grey-for-divider;
  padding: 34px 34px 0 0;
  .elem-wrapper {
    font-size: 18px;
    font-weight: 700;
    img {
      margin-right: 5px;
    }
  }
  .search-left {
    padding-bottom: 30px;
    gap: 10px;
    &.open {
      .vertical-chevrons {
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
      }
      .search-category-wrap {
        display: flex;
        gap: 14px;
        flex-direction: column;
      }
    }
    .search-left-title {
      display: flex;
      justify-content: space-between;
      padding-bottom: 18px;
      border-bottom: 3px solid black;
      width: -webkit-fill-available;
      font-weight: 700;
      font-size: 18px;
      cursor: pointer;
    }
    .search-left-item {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: -webkit-fill-available;
      font-weight: 400;
      transition: all 0.3s ease-in-out;
      &.selected {
        background-color: #f1f1f1;
        .remove-filter {
          display: inline-block;
        }
      }
      .remove-filter {
        display: none;
      }
    }
  }
  .reset-blog-search {
    text-decoration: underline;
    cursor: pointer;
  }
}

.search-tag-wrap {
  display: none;
  gap: 10px;
  .search-tag {
    display: flex;
    align-items: center;
    gap: 5px;

    .bi-x {
      -webkit-text-stroke: 1px;
      display: flex;
    }
  }
}

/* blogs section */
.card {
  position: relative;
  border-radius: 0;
  height: 300px;
  border: none;

  .card-img-top {
    object-fit: contain;
    height: 30rem;
    img {
      width: -webkit-fill-available;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .card-activity-tags {
    position: absolute;
    top: 10px;
    right: 10px;
    gap: 10px;
  }
  .card-body {
    background-color: $white;
    color: $black-light;
    .card-date {
      margin-bottom: 20px;
    }
    .card-title {
      font-size: 24px;
      font-weight: 700;
    }
    .card-text {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .card-blog-title-underline {
      background-color: $black-light;
      width: 100px;
      height: 3px;
    }
    .card-open-link {
      display: flex;
      align-items: center;
      text-transform: uppercase;

      .bi-arrow-right-short {
        font-size: 30px;
      }
    }
  }
}
.card.side-filter-wrapper {
  height: auto;

  @media (max-width: 991px) {
    padding-right: 20px;
  }
}
/* pagination section */

.card-col-prod,
.card-col-blog {
  margin-bottom: 4rem;
  padding-right: 24px;

  a.card {
    border: 1px solid #ccc;

    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .card-body {
    }
  }
}

/* single blog */
.single-blog-wrap {
  text-decoration: none;
  color: $black-light;
  margin-top: 26px;

  .single-blog {
    border-right: 1px solid $grey-for-divider;
  }
  .single-blog-date {
    font-size: 18px;
    .bi-calendar4-week {
      font-size: 22px;
    }
  }
  .single-blog-text {
    font-size: 16px;
    line-height: 32px;
    font-weight: 400;
    p {
      margin-bottom: 0;
    }
  }

  .single-blog-img-with-text {
    img {
      height: 100%;
      width: -webkit-fill-available;
      object-fit: cover;
    }
  }
  .single-blog-img {
    img {
      max-height: 450px;
      min-height: 220px;
      width: -webkit-fill-available;
      object-fit: cover;
    }
  }
  .single-blog-video {
    video,
    iframe {
      width: 100% !important;
    }
  }
  .share-post {
    font-size: 18px;
  }
  .share-icons {
    gap: 20px;
    a {
      color: white;
      background-color: black;
      width: 40px;
      height: 38px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px;
      .bi-facebook,
      .bi-twitter,
      .bi-linkedin,
      .bi-share-fill {
        font-size: 22px;
      }
    }
  }

  .text-block-blog {
    margin-top: 16px;

    p {
      line-height: 32px;
    }
  }
}
.copy-this{
  position: relative;
}
.copy-popup {
  position: absolute;
  text-align: left;
  right: -50px;
  bottom: -30px;
  width: 270px;

  &.hidden {
    display: none;
  }
  &.visible {
    display: block;
  }
}

.search-category-wrap {
  display: none;
  transition: display 0.3s ease-out, max-height 0.8s ease-out;
  padding-left: initial;
}
.search-category-display {
  display: flex;
  gap: 14px;
  flex-direction: column;
  justify-content: flex-start;
  max-height: max-content;
}

/*media screens*/

.search-page-top-area-desktop {
  .wrapper-area {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 20px;
  }

  .form-wrapper {
    width: 40%;
    margin: 0 auto;
    background-color: #f1f1f1;
    border-radius: 25px;
    position: relative;
    border-radius: 20px;

    .form-wrapper:focus {
      box-shadow: rgba(0, 0, 0, 0.16) 0 10px 36px 0,
        rgba(0, 0, 0, 0.06) 0 0 0 1px;
    }

    form {
      display: contents;
      border-radius: 25px;
      padding: 6px;
      background-color: #f1f1f1;

      input {
        display: inline-block;
        width: 100%;
        background: transparent;
        font-size: 16px;
        padding: 7px 0 7px 15px;
        border: 0;
        border-radius: 20px;


        &:focus {
          border-radius: 20px;
        }
      }

      button {
        width: 25px;
        padding: 0;
        background-color: transparent;
        border: 0;
        position: absolute;
        top: 4px;
        margin-left: -30px;
        padding: 5px 15px 5px 0;
      }
    }
  }
}

@media (max-width: 991px) {
  .search-page-top-area-desktop {
    .wrapper-area {
      font-size: 14px;
      .form-wrapper {
        width: 30%;
        form {
          input {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@include lg {
  .blog-left-search-wrap {
    border-right: none !important;
  }
  .single-blog {
    border-right: none !important;
  }
  .form-select {
    padding-right: 24px;
  }
}

@media (max-width: 991px) {
  .search-page-top-area-desktop .wrapper-area .form-wrapper {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .min-hide-768 {
    display: none;
  }
}
@media (min-width: 992px) {
  .min-hide-992 {
    display: none;
  }
}

@media (min-width: 993px) {
  .single-blog-wrap {
    .column-right {
      padding-left: 35px;
    }
  }
}

@media (max-width: 991px) {
  .single-blog-wrap {
    .column-right {
      margin-bottom: 40px;
    }
  }

  .blog-search-container {
    padding: 15px 25px;
  }
}

.blog-grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 25px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Responsive adjustments */

@media (max-width: 768px) {
  .blog-grid-container {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 767px) {
  .max-hide-768 {
    display: none;
  }

  .card-col-prod {
    padding-right: 0;
  }

  .search-page-top-area-desktop .form-wrapper {
    width: 80%;
    margin: 0 auto;
  }
  .wrapper-area {
    flex-wrap: wrap;

  }
}
@media (max-width: 991px) {
  .max-hide-992 {
    display: none;
  }
}

@media (min-width: 768px) {
  .min-hide-500 {
    display: none;
  }

}

.related-blog {
  margin: 20px 0 40px 22px;
  h2 {
    margin-bottom: 15px;
  }
  ul {
    list-style: none;
    padding: 0;

    a {
      display: block;
      margin-bottom: 9px;
      text-decoration: underline;
    }
  }
}



