.product-detail-container {
  width: 1200px;
  max-width: 100%;
  gap: 32px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  transform: scale(1);
}

.single-image-product {
  max-width: 1440px;
  display: flex;
  padding: 0 5rem;
  gap: 24px;
  margin: 32px auto;

  & .image-container {
    max-height: 618px;
    border: 1px solid #DEDEDE;
    flex: 1;
    background: #F8F8F8;
    display: flex;
    align-items: center;
    justify-content: center;

    & img {
      object-fit: contain;
      max-height: 600px !important;
      width: 100%;
    }
  }

  & .product-details {
    position: static;
    max-width: 525px;
  }
}

.section-container {
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.product {
  &-title {
    font-family: "Bebas Neue", sans-serif;
    font-size: 3.4rem;
    margin-bottom: 1rem;
  }

  &-sku {
    font-size: 16px;
    color: #212529;
    margin-bottom: 0;
  }

  &-price {
    font-size: 20px;
    font-family: "Inter", sans-serif;
    font-weight: 600;
  }
}

.saved-money-text {
  color: #bd1202;
  font-size: 16px;
}

.col-1 {
  width: 64.8rem;
}

.col-2 {
  width: calc(100% - 69.4rem);

  &::-webkit-scrollbar {
    display: none;
  }

  .share-menu {
    position: fixed;
    right: 140px;
  }

  & .product-title {
    @include respond(md) {
      display: none;
    }
  }

  & .share-menu {
    @include respond(md) {
      display: none;
    }
  }

  & .desktop-brand-logo {
    @include respond(md) {
      display: none;
    }
  }
}

.accordion-section .tab label.active {
  border-bottom: 1px dashed #c0c0c0;
}

//mobile header

.mobile-header {
  display: none;
  position: relative;
  width: 100%;
  transform: scale(1);
}

//Carousel style

.desktop-swiper-container {
  position: relative;
}

//find retailer btn

.find-store-wrapper {
  display: flex;
  justify-content: center;
  max-width: 220px;
  border: 1px solid $black-dark;
}

.find-store-btn {
  display: flex;
  gap: 8px;
  padding: 14px;
}

//shopify btn
.shopify-buy__btn-wrapper {
  margin-top: 0 !important;
}

// Amazon walmart

.amazon-walmart-block {
  background-color: #f1f1f1;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  padding: 15px 21px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
  flex-wrap: wrap;


  & .stores {
    display: flex;
    gap: 20px;
  }

  & a {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #000;
    transition: all 0.2s;

    &:hover {
      color: #4e4e4e;
    }
  }

  & img {
    max-width: 20px;

    &.amazon-logo {
      max-width: 75px;
    }
  }

  & span {
    font-size: 18px;
    font-weight: 500;
  }
}

// Disclaimer block

.disclaimer {
  background-color: #f1f1f1;

  & p {
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    margin-bottom: 0;
  }
}

/* Acordeon styles */

.accordion-section {
  background-color: $white-dark;
  padding: 8.9rem;
  padding-left: 6.4rem;
}

.half {
  width: 100%;
}

.tab {
  position: relative;
  margin-bottom: 1px;
  width: 100%;
  color: $black;
  overflow: hidden;
}

.tab input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.tab label {
  display: flex;
  align-items: center;
  position: relative;
  background: $white-dark;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  font-size: 1.8rem;
  line-height: 3.2rem;
  line-height: 3;
  cursor: pointer;
  height: 9rem;
  border-bottom: 1px solid rgba(#000000, 1);
  transition: all 0.3s;
}

.tab-content {
  max-height: 0;
  overflow: hidden;
  background: $white-dark;
  -webkit-transition: max-height 0.35s;
  -o-transition: max-height 0.35s;
  transition: max-height 0.35s;
}

.tab-element {
  margin: 1.5rem 0;
}

/* .active*/
.tab input:checked ~ .tab-content {
  max-height: 100vh;
}

.tab input:checked ~ label {
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}

/* Icon */
.tab label::after {
  position: absolute;
  right: 0;
  display: block;
  width: 3em;
  height: 3em;
  line-height: 3;
  text-align: center;
  -webkit-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
}

.tab input[type="checkbox"] + label::after {
  content: "+";
}

.tab input[type="radio"] + label::after {
  content: "\25BC";
}

.tab input[type="checkbox"]:checked + label::after {
  transform: rotate(315deg);
}

.tab input[type="radio"]:checked + label::after {
  transform: rotateX(180deg);
}

.tag-line {
  font-family: "Inter", sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 15px;
}

.technical-specifications-list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 3.2rem;
  color: #212529;

  & li {
    font-family: "Inter", sans-serif;
    font-size: 1.3rem;
    line-height: 2.4rem;
  }

  & span {
    font-weight: 600;
  }

  & a {
    font-size: 1.4rem;
    color: #000;
    cursor: pointer;
  }

  li {
    a {
      text-decoration: underline;
      font-family: "Inter", sans-serif;
      font-size: 1.4rem;
    }
  }

  & .color-disclaimer {
    color: #212529;
    margin-bottom: 3.2rem;
    margin-top: 1.6rem;
  }
}

.documentation {
  & li a {
    font-size: 1.4rem;
    font-family: "Inter", sans-serif;
    line-height: 2.4rem;
    text-decoration: underline;
  }
}

// Benefits section
.benefits {
  display: flex;
  align-items: flex-start;
  gap: 2rem;

  &-img {
    img {
      width: 7.5rem;
      height: 7.5rem;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &-no-image {
    width: 7.5rem;
    height: 7.5rem;
  }

  &-default-img {
    background: $grey-for-benefits;
    height: 7.5rem;
    min-width: 7.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// Share button
.share-menu {
  position: absolute;
  top: 0;
  right: 35px;
}

.share-menu div,
.menu-open-button {
  position: absolute;
  top: -12px;
  background: #fff;
  border-radius: 100%;
  width: 48px;
  position: absolute;
  height: 48px;
  text-align: center;
  line-height: 48px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: -webkit-transform ease-out 200ms;
  transition: -webkit-transform ease-out 200ms;
  transition: transform ease-out 200ms;
  transition: transform ease-out 200ms, -webkit-transform ease-out 200ms;
}

.menu-open {
  display: none;
}

.menu {
  position: absolute;
  width: 48px;
  height: 48px;
  text-align: center;
  box-sizing: border-box;
  font-size: 26px;
}

.share-icon {
  color: #596778;
}

.share-menu {
  & div:nth-child(2) {
    -webkit-transition-duration: 180ms;
    transition-duration: 180ms;
  }

  & div:nth-child(3) {
    -webkit-transition-duration: 180ms;
    transition-duration: 180ms;
  }

  & div:nth-child(4) {
    -webkit-transition-duration: 180ms;
    transition-duration: 180ms;
  }

  & div:nth-child(5) {
    -webkit-transition-duration: 180ms;
    transition-duration: 180ms;
  }

  & div:nth-child(6) {
    -webkit-transition-duration: 180ms;
    transition-duration: 180ms;
  }
}

.menu-open-button {
  z-index: 2;
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-transition-duration: 400ms;
  transition-duration: 400ms;
  cursor: pointer;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);

  &.active {
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transition-duration: 200ms;
    transition-duration: 200ms;
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.12);

    & ~ a {
      -webkit-transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
      transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
    }
  }
}

.menu-open-button.active {
  & ~ div:nth-child(2) {
    transition-duration: 180ms;
    -webkit-transition-duration: 180ms;
    -webkit-transform: translate3d(-42.03006px, -23.33095px, 0);
    transform: translate3d(-42.03006px, -23.33095px, 0);
  }

  & ~ div:nth-child(3) {
    transition-duration: 280ms;
    -webkit-transition-duration: 280ms;
    -webkit-transform: translate3d(-41.86291px, 24.62064px, 0);
    transform: translate3d(-41.86291px, 24.62064px, 0);
  }

  & ~ div:nth-child(4) {
    transition-duration: 380ms;
    -webkit-transition-duration: 380ms;
    -webkit-transform: translate3d(5.08361px, 50.99997px, 0);
    transform: translate3d(5.08361px, 50.99997px, 0);
  }

  & ~ div:nth-child(5) {
    transition-duration: 480ms;
    -webkit-transition-duration: 480ms;
    transform: translate3d(52.9466px, 22.47586px, 0);
    transform: translate3d(52.9466px, 22.47586px, 0);
  }

  & ~ div:nth-child(6) {
    transition-duration: 580ms;
    -webkit-transition-duration: 580ms;
    -webkit-transform: translate3d(46.9466px, -29.47586px, 0);
    transform: translate3d(46.9466px, -29.47586px, 0);
  }

  & ~ div:nth-child(7) {
    transition-duration: 660ms;
    -webkit-transition-duration: 680ms;
    -webkit-transform: translate3d(2.08361px, -48px, 0);
    transform: translate3d(2.08361px, -48px, 0);
  }
}

.menu-open-button.active img:nth-child(2) {
  display: inline;
}

.menu-open-button.active img:nth-child(1) {
  display: none;
}

.menu-open-button img:nth-child(2) {
  display: none;
}

.share-menu div {
  background-color: transparent;
  height: 40px;
  width: 40px;
  line-height: 40px;
}

.share-menu div.active {
  background-color: #000;
}

.share-menu i {
  font-size: 22px;
  line-height: 44px;
  color: #fff;
}

// Locally Styles

#lcly-button-0 {
  display: flex !important;
  flex-direction: column;
}

div#lcly-button-0 {
  float: none !important;
  margin-top: 10px !important;
}

.lcly-primary-trigger {
  order: 2;
  text-decoration: underline !important;
  font-size: 16px !important;
  font-weight: 500 !important;

  & span {
    position: relative;
  }
}

.lcly-primary-trigger span::before {
  content: "";
  background-image: url("../../../apps//front//static/img/icons/location_on.svg");
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 20px;
}

.lcly-dealers-wrap .lcly-dealer,
.lcly-related-product {
  background-color: #fff !important;
  width: 50% !important;
}

.lcly-dealers-wrap {
  width: 231px;
  margin: 0 0 0 3%;
  position: relative;
  min-height: 41px;
  overflow-y: hidden;

  // &::before {
  //   content: "";
  //   background-image: url("../../../apps//front//static/img/icons/storefront.svg");
  //   position: absolute;
  //   left: 2.3rem;
  //   top: 2.5rem;
  //   transform: translateY(-50%);
  //   width: 16px;
  //   height: 14px;
  //   z-index: 1;
  // }
}

.lcly-dealer-0 {
  min-height: 100% !important;
  padding: 0;

  &:hover {
    background-color: #f7f7f7 !important;
    border: 1px solid #000 !important;
  }
}

.lcly-dealers-wrap .lcly-dealer, .lcly-related-product {
  border: none !important;
}

.lcly-dealers-wrap .lcly-dealer.w-2-dealers {
  width: 231px;
  margin: 0 0 0 3%;
  position: relative;

  &::before {
    content: "";
    background-image: url("../../../apps//front//static/img/icons/storefront.svg");
    position: absolute;
    left: 22px;
    top: 24px;
    transform: translateY(-50%);
    width: 16px;
    height: 14px;
    z-index: 4;
  }
}

.lcly-dealers-wrap {
  display: flex;
  gap: 2.4rem;
}

.lcly-dealers-wrap-outer,
.lcly-related-products-wrap {
  width: 101% !important;
}

.lcly-dealers-wrap {
  & .lcly-dealer.w-2-dealers {
    width: 100% !important;
  }
}

.lcly-dealers-wrap .lcly-dealer,
.lcly-related-product {
  padding: 1.6rem 2rem 1.6rem 4rem !important;
  @media(max-width: 550px) {
    padding: 1.6rem 0rem 1.6rem 5rem !important;
  }
}

.lcly-dealers-wrap .lcly-dealer .lcly-icon-marker {
  top: 16px !important;
  left: 18px !important;
  display: none;
}

.lcly-dealer-directions {
  display: none;
}

.lcly-dealer-name {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  padding-left: 10px !important;
  color: #000;
}

.lcly-dealer-distance {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400 !important;
  line-height: 20px;
  padding-left: 10px !important;
  color: #000 !important;
}

.lcly-location-features {
  display: none !important;
}

a.lcly-primary-trigger {
  max-width: max-content;
  padding: 0 20px;
  text-align: left;
}

a.lcly-primary-trigger span {
  padding: 0 !important;
  display: block !important;
  width: max-content;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-size: 16px !important;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  margin-bottom: 2.4rem;
}

.lcly-dealers-wrap .lcly-dealer.w-2-dealers {
  transition: all 0.2s;
}

.lcly-dealers-wrap .lcly-dealer.w-2-dealers:hover {
  background-color: #f7f7f7 !important;
  border: 1px solid #000;
}

.lifestyle-img {
  width: 25rem;
}

.rating {
  display: flex;
  align-items: center;
  gap: 8px;

  & img {
    width: 20px !important;
  }

  .bv_stars_component_container {
    display: none !important;
  }
}

.bottom-info {
  display: flex;
  justify-content: space-between;
  gap: 4px;
}

// Exlusive model links

.dealers-list {
  transition: all 0.2s;

  & li:hover {
    a {
      color: #575960;
    }
  }
}

// Media queries

@include respond(xl) {
  .share-menu {
    right: 35px;
  }
}

@include respond(lg) {
  .share-menu {
    right: 80px;
  }
}

@include respond(md) {
  .documentation {
    padding-left: 2.5rem;
  }
  .col-1 {
    width: 100%;
  }
  .col-2 {
    width: 100%;
  }

  .accordion-section {
    padding: 5.9rem 4.4rem;
  }

  //mobile header
  .mobile-header {
    display: block;
  }

  a.lcly-primary-trigger {
    width: 230px !important;
  }
}

@include respond(sm) {
  .documentation {
    padding-left: 3rem;
  }

}

.related_product {
  width: 1200px;
  overflow: hidden;
}


.product-carousel-container {
  overflow: hidden;
  position: relative;
  width: 100%; /* Adjust as needed */
}

.product-carousel-container .carousel-items {
  display: flex;
  transition: transform 0.5s ease;
}

.product-carousel-container .item {
  flex: 0 0 auto;
  width: 100%; /* Adjust as needed */
  padding: 10px;
  box-sizing: border-box;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px; /* Adjust spacing between items */
}

.product-carousel-container .item:last-child {
  margin-right: 0;
}

.product-carousel-container button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.product-carousel-container .prev {
  left: 10px; /* Adjust position */
}

.product-carousel-container .next {
  right: 10px; /* Adjust position */
}

/* Additional styling for button appearance, hover effects, etc. */

.price-container {
  display: flex;
  gap: 16px;
  align-items: flex-end;
}

// variant slider style 

.owl-six {
  & .owl-stage {
    margin: 0;
    height: fit-content;
    padding-bottom: 5px;
    max-height: 70px;
  }
}
.swatch-colors-swiper {
  width: 100%;
  height: 100%;

  & .swiper-slide {
    width: 30px;
  }
}

.product-price {
  margin-top: 5.5rem;
}

.product-price.price-container {
  margin-top: 5.5rem;
}

.product-note {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  margin-top: 40px;
  margin-bottom: 0;
}


@media (max-width:920px){
  .single-image-product {
    padding: 0 20px;
    flex-direction: column;
    & .product-details {
      position: static;
      max-width: inherit;
    }
     
    & .product-details {
      padding: 0;
    }
  }
}

.sezzle-payment-amount.sezzle-button-text.sezzleindex-0 {
  font-weight: 400 !important;
  font-size: 14px !important;
}
.sezzle-info-icon.sezzle-modal-link {
  display: none !important;
}