#checkout {
  margin-bottom: 48px;

}
.empty-cart-container {
  font-family: 'Roboto', serif;
  display: flex;
  flex-direction: column;
  gap: 18px;
  justify-content: center;
  min-height: 300px;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  a {
    max-width: 350px;
    font-size: 14px;
    font-weight: 500;
  }
}
.main-checkout-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  flex: 1 0 0;
  form {
    min-width: 100%;
    width: 100%;
    max-width: 100%;
  }
}
.checkout-breadcrumb {
  padding: 32px 0;
  a {
    font-family: Inter, serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    margin-right: 8px;
    margin-left: 8px;
  }

  .breadcrumb-item {
    &:before{
      color: #98A2B3;
    }
  }
  .breadcrumb-item.active {
    color: #667185;
    font-family: Inter, serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-right: 8px;
  }
}
