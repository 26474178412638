.line-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;
  align-self: stretch;
  .line-item {
    display: flex;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
    &__image {
      display: flex;
      width: 200px;
      height: 200px;
      padding: 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 10px;
      border: 1px solid var(--Grey-300, #D0D5DD);
      background: var(--Grey-50, #F9FAFB);
      img {
        width: 198.908px;
        height: 198.908px;
        flex-shrink: 0;
      }
    }
    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      flex: 1 0 0;
      &__product-details {
        display: flex;
        align-items: flex-start;
        gap: 47px;
        align-self: stretch;
        &__title {
          color: #000;
          font-feature-settings: 'cv04' on, 'cv03' on, 'cv01' on;
          font-family: Bebas Neue;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px; /* 116.667% */
        }
        &__price {
          color: var(--Grey-900, #101928);
          font-feature-settings: 'cv01' on, 'cv04' on, 'cv03' on;
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 145%; /* 26.1px */
        }

      }
      &__quantity {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        &__remove {
          img {
            width: 25px;
            height: 25px;
            cursor: pointer;
          }
        }
        .quantity {
          border: 1px solid #333;
          width: 127px;
          height: 40px;
          border-radius: 40px;
          display: flex;
          flex-shrink: 0;
          justify-content: center;
          align-items: center;
          &__minus {
            width: 100%;
            text-align: center;
            cursor: pointer;
          }
          &__input {
            width: 100%;
            height: 40px;
            text-align: center;
          }
          &__plus {
            width: 100%;
            text-align: center;
            cursor: pointer;
          }
        }
      }

    }
  }
}

