.procut-color-circle {
    display: flex;
    justify-content: flex-end;
    -webkit-box-align: end;
    align-items: flex-end;
    gap: 10px;
    .card-color {
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
      .active {
        outline: 1px solid black;
        outline-offset: 2px;
      }
}