.galery-cta-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    &.space {
      width: calc(100% - 28px);
      gap: 20px;
      margin:0 14px;
    }

    @include respond(md) {
      grid-template-columns: repeat(2, 1fr) !important;
    }

    @include respond(sm) {
      grid-template-columns: repeat(1, 1fr) !important;
    }

  }

  .galery-cta-title {
    &.space {
      margin-left: 12px;
    }
  }

  .galery-cta-item {
    transition: all 0.3s ease;
    position: relative;

    &.zoom:hover {
      box-shadow: 0 2.4rem 4rem 0 rgba(0, 0, 0, .25);
      scale: 1.05;
    }

    &:hover .img-square-container.active .img-square-container__layer {
      opacity: 1;
    }
    &:hover  .img-square-container.active img {
      filter: grayscale(1) brightness(3);
    }
  }
  
  .img-square-container{
    flex-basis: 50%;
    position:relative;
    transition: all 0.3s ease;
    z-index: -1;
    &__layer {
      position: absolute;
      opacity: 0;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 999;
      transition: .3s ease all;
    }

  }
  
  .img-square-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s ease;
  }
  
  
  .image-caption {
    color: white;
    font-size: 2rem;
  }

  .content {
    position: absolute;
    padding-bottom: 4.7rem;
    text-align: center;
    top: 0;
    left: 15%;
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    z-index: 99999999;
  }

  .bellow-image-content {
    text-align: center;
    & .title {
      font-size: 3rem;
      font-weight: 700;
      margin: 2.5rem auto;
      padding: 0 1.5rem;
    }

    & .text {
      padding-bottom: 1.6rem;
    }
  }

  
  @media (max-width: 768px) {
    .img-square-container {
      flex-basis: 100%;
    }
  }
  