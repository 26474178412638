.collapsed-block {
  .accordion-item {
    border-bottom: 1px solid #000000;
    .accordion-header {
      .accordion-button {
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
        padding: var(--bs-accordion-btn-padding-y) 0;
      }
    }
    .accordion-body {
      --bs-accordion-body-padding-x: initial;
    }
  }
  .accordion-flush .accordion-item:last-child {
    border-bottom: 1px solid #000000;
  }
  .accordion-button:not(.collapsed) {
    color: #000000;
    background-color: initial;
    -webkit-box-shadow: initial;
    box-shadow: initial;
  }
  .accordion-button:focus {
    border-color: initial;
    -webkit-box-shadow: initial;
    box-shadow: initial;
  }
  .accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: url("../../../apps/front/static/img/icons/plus.svg");
    -webkit-transition: initial;
    transition: initial;
    background-position: center;
    background-size: 14px;
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url("../../../apps/front/static/img/icons/minus.svg");;
    -webkit-transform: initial;
    transform: initial;
    background-position: center;
    background-size: 14px;
  }
}