/** Bootstrap **/
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");
@import 'animate.css';

/** abstracts **/
@import "abstracts/mixins";

/** Base **/
@import "base/variables";
@import "base/colors";
@import "base/breakepoints";
@import "base/general";
@import "base/fonts";
@import "base/typography";

/** Home **/
@import "home_page";

/** Blogs **/
@import "blogs/blogs";

/** Brands **/
@import "brands/brands";

/** Landing Pages **/
@import "landers/early_access";

/** Components **/
@import "components/buttons";
@import "components/tabs";
@import "components/breadcrumbs";
@import "components/stats_block";
@import "components/products_carousel_block";
@import "components/gallery_cta_block";
@import "components/featured_blogs_block";
@import "components/single_image_block";
@import "components/gallery_media_block";
@import "components/single_video_block";
@import "components/title_image_text_block";
@import "components/collapsed_block";
@import "components/color-circle";
@import "components/grey_line";
@import "components/black_line";
@import "components/product_card";
@import "components/filters";
@import "components/videos";
@import "components/shop_by_brand_block";
@import "components/grouped_category_block";
@import "components/forms";
@import "components/confirmation_message";
@import "components/product_skeleton";
@import "components/modal";
@import "components/cookieconsent";
@import "components/live_chat";
@import "components/pagination";
@import "components/magic_zoom";
@import "components/magiczoomplus";
@import "components/news_letter_popup";
@import "components/column_block";
@import "components/product_tabs";
@import "components/product_carousel";
@import "components/dropdown_with_checkbox";
@import "components/product_register_tooltip";
@import "components/input";

/** Modules **/
@import "modules/tooltip";
@import "modules/form";

/** Layout **/
@import "layout/header";
@import "layout/footer";
@import "layout/grid";
@import "layout/main-menu";
@import "layout/top-header";
@import "layout/sidebar";

/** Pages **/
@import "pages/404";
@import "pages/registration";
@import "pages/profile";
@import "pages/contact_page";
@import "pages/contact_form";
@import "pages/product_details";
@import "pages/login";
@import "pages/reset_password";
@import "pages/confirm_reset_password";
@import "pages/accounts/change_password";
@import "pages/single_article";
@import "pages/products";
@import "pages/invoice";

/** Checkout **/
@import "checkout/main";

/** Libraries **/
@import 'swiper/css/bundle'; 

