form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 365px;
  width: 100%;
  margin: auto;
}

.form-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input {
  font-size: 12pt;

  &::placeholder {
    font-size: 12pt;
    color: $gris;
  }
}

.input-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: baseline;
  margin: auto;

  label {
    font-size: 12pt;
    font-weight: bold;
    color: $blue-fonce;

    span {
      color: $rouge;
      font-size: 14pt;
      margin-right: 5px;
    }
  }

  input {
    width: 100%;
    border: 1px solid $gris;
    background-color: transparent;
    padding: 5px;
  }
}

.input-wrapper-with-icon {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: baseline;
  margin: auto;

  label {
    font-size: 12pt;
    font-weight: bold;

    span {
      color: $rouge;
      font-size: 14pt;
      margin-right: 5px;
    }
  }

  .input-box {
    width: 100%;
    border: 1px solid $gris;
    background-color: transparent;
    display: flex;
    align-items: center;
    padding: 5px;
    gap: 5px;

    img {
      width: 18px;
    }

    input {
      width: 100%;
      background-color: transparent;
      border: 0;
    }
  }
}

.error-msg {
  display: none;
  color: red;
  font-size: 12pt;
}

//date picker 
.datepicker{
  font-size: 1.5rem;
} 
.date-picker {
  border: 1px solid #A8A8A8 !important;
  flex-wrap: nowrap !important;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 12px 16px;
  margin: 0 0 12px 0;

  & input {
    padding: 0;
  }

  &:hover {
    border: 1px solid #000 !important;
  }

  & input {
    border-bottom: none;

    &:focus {

      box-shadow: none;
    }
  }

}

.blog-filter-form {
  margin-left: 25px;
  flex-direction: row;

  & select {
    padding-top: 0;
    border: 0;
  }

  & .sort-by-label {
    color: #61646b;
  }
}

.input-group-text  {
  font-size: 1.5rem;
}
.preferences{
  &-title{
    align-self: var(--position);
    margin-left:15px;
    margin-right: 15px;
  }
  &-subtitle{
    align-self: var(--position);
    margin-left:15px;
    margin-right: 15px;
  }
  &-form{
    width: 100%;
    max-width: 600px;
    margin-left:15px;
    margin-right: 15px;;
    align-self: var(--position);

    & .dropdown {
      width: 100%;
    }
  }
  &-email{
    height: 48px;
    padding: 16px;
    color: #98A2B3;
    background-color: #fff;
    border: 1px solid #D0D5DD;
    font-size: 14px;
    width: 100%;
  }
  &-checkbox-text {
    font-size: 12px;
    margin-left: 5px;
    width: calc(100% - 25px);
  }
  &-text-container{
    display: flex;
    align-items: flex-start;
    max-width: fit-content;
    align-self: var(--position);
  }
  &-form-container{
    display:flex; 
    flex-direction:column; 
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  &-checkbox{
    height: 20px;
    width: 20px;
    color: #000;
    accent-color: #fae5a2;
    &:checked {
      accent-color: #fae5a2;
    }
    &:hover {
      accent-color: #fae5a2;
    }
  }

  &-submit-button {
    max-width: 200px;
    align-self: var(--position);
    border-radius: 0 !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }

}

.input-container {
  display: flex;
  gap: 32px;

  @media (max-width: 550px) {
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
  }

}

#newsletter-form-on-popup {
  align-items: center;
}

.error-text {
  text-align: left;
  margin-top: -20px;
  width: 100%;
 & #select-error {
    color: #e41919;
    font-size: 14px;
    text-align: left !important;
  }
}

.textarea-form-group {
  margin-top: -8px;
  .textarea {
    padding: 10px 16px;
    font-size: 16px;
  }

  & .parsley-errors-list.filled {
    margin-bottom: 0;
  }
}

.text-input {
  position: relative;
  & .parsley-errors-list.filled {
    position: absolute;
    bottom: -30px;
    left: 0;
    display: block;
    list-style: none;
    padding: 0;
    z-index: 9999;
    color: #dc3545 !important;
    font-size: 14px !important;
    & .parsley-required {
      font-size: 14px !important;
      color: #dc3545;
    }
  }
}

.parsley-errors-list.filled {
  display: block;
  list-style: none;
  padding: 0;
  margin-top: 2px;
  color: #dc3545 !important;
  font-size: 14px !important;
  & .parsley-required {
    font-size: 14px !important;
    color: #dc3545;
  }
}


.select-form-group {
  position: relative;

  & .parsley-errors-list.filled {
    position: absolute;
    bottom: -30px;
    display: block;
    list-style: none;
    padding: 0;
    z-index: 9999;
    font-size: 14px !important;
    & .parsley-required {
      font-size: 14px !important;
      color: #dc3545;
    }
  }
}

.date-picker {
  position: relative;
  & .parsley-errors-list.filled {
    position: absolute;
    bottom: -32px;
    left: 0px;
    display: block;
    list-style: none;
    padding: 0;
    z-index: 9999;
    color: #dc3545 !important;
    font-size: 14px !important;
  }

}

.checkbox-group {
  position: relative;
  & .parsley-errors-list.filled {
    position: absolute;
    bottom: -30px;
    display: block;
    list-style: none;
    padding: 0;
    z-index: 9999;

    & .parsley-required {
      font-size: 14px !important;
      color: #dc3545;
    }
  }
}