.invoice-container {
  max-width: 1350px;
  margin: 0 auto;
  margin-top: 32px;
  padding: 0 5rem;
  @media (max-width: 600px) {
    padding: 0 2.4rem;
  }
}

.order-number__text {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 24px;
  line-height: 28px;

  @media (max-width: 600px) {
    font-size: 20px;
    line-height: 20px;
  }
}

.store-adress {
  color: #838383;
  font-size: 12px;
  margin-bottom: 32px;
}

.order-date {
  font-weight: 600;
  font-size: 18px;
  & span {
    font-weight: 400 !important;
  }
  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 20px;
  }
}

.invoice-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}

.heading-invoice {
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;

  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 16px;
  }
}
.order-summary {
  font-size: 18px;

  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 16px;
  }
}

.item-image-price {
  display: flex;
  font-size: 18px;
}

.customer-info-container {
  background-color: #f1f1f1;
  overflow: hidden;
  padding: 2.4rem;
  margin-bottom: 32px;

  & .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;

    @media (max-width: 600px) {
      font-size: 18px;
      line-height: 18px;
    }
  }
}

.first-col {
  grid-column: 1;
}

.second-col {
  grid-column: 2;
  word-break: break-word;
}

.info-client {
  font-size: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: dense;


  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 22px;
  }
 
}

.order-list__product-description-cell {
  flex: 2;
  @media (max-width: 600px) {
    font-size: 16px;
  }
}

.order-list__price-cell {
  @media (max-width: 600px) {
    font-size: 16px;
  }
}

.invoice-row {
  display: flex;
}

.seperat-line {
  height: 1px;
  background-color: #c4c4c4;
}

.subtotal-line {
  display: flex;
  gap: 10px;

  &__title {
    flex: 2;
    color: #8f8f8f;
  }
}

.subtotal-line.total {
  align-items: center;
  & p {
    margin: 0;
  }
  .subtotal-line__value {
    font-size: 25px;
    font-weight: 600;
  }
}

.generate-pdf {
  margin-bottom: 32px;
  & .order-number__text {
    font-size: 25px;
    line-height: 25px;
    font-weight: 500;
    margin-bottom: 24px;
  }
}

.order-details {
  display: flex;
  gap: 24px;

  & p {
    font-size: 18px;

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.invoice-footer {
  margin-top: 6rem;
  margin-bottom: 32px;
  display: flex;

  @media (max-width: 600px) {
    flex-direction: column;
  }

  & a {
    text-decoration: underline;
    font-size: 16px;
  }

  .disclaimer__subtext {
    font-size: 16px;

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }
}

#save-pdf {
  border: none;
  display: block;
  margin-left: auto;
  border-radius: 5px;
  padding: 1rem 1.5rem;
  font-size: 16px;
  position: relative;
  align-self: center;

  @media (max-width: 600px) {
    margin-left: 0;
    align-self: flex-start;
    margin-top: 20px;
  }
  & img {
    margin-bottom: 4px;
    margin-left: 4px;
  }
}
