
@use "@material/floating-label/mdc-floating-label";
@use "@material/line-ripple/mdc-line-ripple";
@use "@material/notched-outline/mdc-notched-outline";
@use "@material/textfield";

@include textfield.core-styles;


* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}


.mdc-text-field * {
  font-size: 100% !important;

}

.warning-sign {
  max-height: 2.5rem;
  vertical-align: bottom;
}

*:focus {
  outline-width: 0 !important;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: white;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  font-family: 'Roboto', sans-serif;

  display: flex;
  flex-direction: column;
}

.main-container {
  max-width: 1410px;
  width: 100%;
  margin: 0 auto;
  padding: 0 5rem;
}

.product-container {
  max-width: 1410px;
  width: 100%;
  margin: 0 auto;
}

.search-container {
  max-width: 1410px;
  width: 100%;
  margin: 0 auto;
}

header {
  flex: 0 0 auto;
}

main {
  flex: 1 1 auto;
}

footer {
  flex: 0 0;
}

.px-16px {
  padding-left: 16px;
  padding-right: 16px;
}

.margin-top-74px {
  margin-top: 7.4rem;
}

.margin-top-40px {
  margin-top: 4rem;
}

.icon-flip {
  transform: rotate(-180deg);
}

.display-none {
  display: none !important;
}

.display-block {
  display: block !important;
}

.max-height-600{
  max-height: 600px;
}

.border-rad-5px {
  border-radius: 5px !important;
}

.margin-top-minus-20px {
  margin-top: -18px;
}

.margin-top-minus-30px {
  margin-top: -30px;
}

.margin-bottom-18px {
  margin-bottom: 18px;
}

.margin-bottom-80px {
  margin-bottom: 80px;
}

.padding-right-25px {
  padding-right: 25px;
}
.padding-left-md-25px {
  padding-left: 25px;

  @media (max-width:991px){
    padding-left: 0;
  }
}


.padding-left-25px {
  padding-left: 25px !important;
}

.padding-right-md-25px {
  @media (min-width:992px){
    padding-right: 25px;
  }
}

.divider {
  height: 2px;
  background-color: #b7b7b7;
  display: block;
}

//bootstrap adjust
.px-5 {
  padding: 0 25px !important;
}

.p-5 {
  padding: 3rem !important;
}

.g-5 {
  --bs-gutter-y: 24px;
  --bs-gutter-x: 24px;
}

.g-7 {
  --bs-gutter-y: 50px;
  --bs-gutter-x: 50px;
}

@include respond(lg){
  html {
    font-size: 58%;
  }
}

@include respond(md){
  html {
    font-size: 55%;
  }
}

@include respond(sm){
  html {
    font-size: 49%;
  }
  .main-container {
    padding: 0 3rem;
  }
}


@include respond(xs){
  html {
    font-size: 42%;
  }
}

