.main-footer {
  background-color: $black-light;
  color: $white-dark;
  font-family: "Poppins", "Arial", sans-serif;
  padding: 50px 0 100px;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  & .False {
    display: none;
  }

  /* footer columns */
  .footer-column {
    .footer-title {
      color: $white-dark;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
    }
    .footer-arrow-button {
      display: none;
    }
    .footer-content {
      padding-left: 0;
      list-style: none;
      .footer-link {
        color: $white-dark;
        text-decoration: none;
        padding: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }
    .footer-content-news-letter {
      padding-left: 0;
      list-style: none;
      .news-letter {
        color: $white-dark;
        text-decoration: none;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        input {
          background-color: $black-light;
          padding: 10px;
          border: 1.5px solid $white-dark;
          width: 100%;
          color: $white-dark;
          cursor: text;
        }
        input::-webkit-input-placeholder {
          font-family: "Poppins", "Arial", sans-serif;
          font-size: 14px;
        }
        a {
          color: $white-dark;
          padding-left: 25px;
          font-size: 25px;
          cursor: pointer;
        }
      }
    }
  }

  .news-letter-footer {
    p {
      font-size: 14px;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 32px;
      label[for="id_email"] {
        display: none;
      }
      input {
        height: 48px;
        padding: 16px;
        background-color: transparent;
        border: 1px solid #F1F1F1;
        color: #fff;
        font-size: 14px;

        &::placeholder {
          color: #F1F1F1 !important;
          font-size: 14px;
        }
      }
    }
  }

  & .footer-social-media {
    margin-top: 30px;
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    @media(max-width:991px){
      justify-content: center;
    }
    & img {
      width: 48px;
      height: 48px;
    }
  }
}

/* media screens */
@include lg {
  .main-footer {
    padding: 30px 0 100px;

    /* footer columns */
    .footer-column {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      border-bottom: 2px solid $grey-light-2;
      &:last-child {
        border-bottom: none;
      }
      .footer-arrow-button {
        display: block;
        cursor: pointer;
        text-decoration: none;
        color: $white-dark;
        position: absolute;
        right: 40px;
        top: 22px;
        font-weight: 800;
        font-size: 20px;
        -moz-transition: transform 0.4s;
        -webkit-transition: transform 0.4s;
        transition: transform 0.4s;
      }
      .footer-title {
        padding: 20px 0;
      }
      .footer-content {
        display: none;
      }
      .footer-content-mobile {
        display: flex !important;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        padding: 20px 15px;
        border-top: 2px solid $grey-light-2;
      }
      .footer-content > li {
        flex-basis: 50%;
      }
      .footer-content-news-letter {
        padding: 0 30px;
      }
    }

    .news-letter-footer {
      p {
        padding: 0 16px;
      }
      form {
        padding: 16px;
        justify-content: space-between;
        input {
          width: inherit;
        }
      }
    }
    & p {
      padding: 0 16px;
    }
  }
}
