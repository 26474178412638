
.main-menu-wraper {
    position: relative;
    border-bottom: 1px solid #C7C7C7;
    @media(max-width: 800px) {
        display: none;
    }
}

.sub-menu-wraper {
    position: absolute;
    width: 100%;
    top: 95px;
    left: 0;
    z-index: 9999999;
    border-top: 1px solid #C7C7C7;
    display: none;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
    background: #fff;
}

.sub-menu-container {
  max-width: 1410px;
  width: 100%;
  margin: 0 auto;
  padding: 0 5rem;
}
.main-menu-container {
    max-width: 1410px;
    width: 100%;
    padding: 0 5rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.main-menu {
    &__logo {
        & img {
            width: 250px;
            height: 100%;
            object-fit: contain;
            @media (max-width:950px){
                width: 160px;
            }
        }
    }

    &__nav {
        &-list {
            padding: 0;
            display: flex;
            list-style: none;
            margin: 0;
            max-width: 100%;
        }

        &-item {
            padding: 38px 28px;
            @media (max-width:950px){
              padding: 38px 10px;
            }
            cursor: pointer;

            &.mobile {
              display: none;
            }

            &.False {
              display: none;
            }
            
            &:hover .main-menu__nav-link {
              font-weight: 800;
            }  
   
        }

        &-link {
            font-weight: 600;
            transition: all 0.1s;
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            color: black;
            @media (max-width:950px){
                font-size: 1.5rem;
            }

            &::after {
                content: attr(data-text);
                height: 0;
                visibility: hidden;
                overflow: hidden;
                user-select: none;
                pointer-events: none;
                font-weight: 800;
              
                @media speech {
                  display: none;
                }
            }
               
        }
    }

    &__right {
        display: flex;
        align-items: center;
        gap: 16px;

        & #search-icon {
          cursor: pointer;
          &.active{
            opacity: 0.5;
          }
        }
    }


    & .sub-menu {
        display: flex;
        flex-wrap: wrap;
        column-gap: 5rem;
        row-gap: 2rem;
        width: fit-content;
        margin: 0 auto;

        & .sub-menu-lvl-1 {
            &.False {
              display: none;
            }

            &.has-image > a {
                flex-direction: column-reverse;
                gap: 16px;
                align-items: center;
                margin-top: 4rem;
                font-size: 10px;
                line-height: 13px;
                max-height: 200px;
                text-align: center;
                transition: all .1s;
            }

            &.mobile {
              display: none;
            }
        }

        & .sub-menu-lvl-1 > a {
            font-weight: 600;
            transition: all 0.1s;
            display: flex;
            flex-direction: column;
            max-width: 140px;
            margin-top: 2rem;
            margin-bottom: 1.6rem;
            font-size: 1.6rem;

            &:hover {
              font-weight: 700;
            }

            &::after {
              content: attr(data-text);
              height: 0;
              visibility: hidden;
              overflow: hidden;
              user-select: none;
              pointer-events: none;
              font-weight: 800;
            
              @media speech {
                display: none;
              }
          }
            & img {
                filter: brightness(0);
                max-height: 25px;
                object-fit: contain;
                width: fit-content;
            }
        }

        & .sub-menu-lvl-2 {
            &.False {
              display: none;
            }

            &.mobile {
              display: none;
            }
        }

        & .sub-menu-lvl-2 > a {
          transition: all 0.1s;
            display: block;
            margin-bottom: 1.2rem;
            font-size: 1.6rem;
            &:hover {
              font-weight: 600;
            }

            &::after {
              content: attr(data-text);
              height: 0;
              visibility: hidden;
              overflow: hidden;
              user-select: none;
              pointer-events: none;
              font-weight: 700;
              display: block;
              @media speech {
                display: none;
              }
          }
        }
    }
}

// header search 
.header-search-container {
  max-width: 1410px;
  width: 100%;
  padding: 0 5rem;
  margin: 0 auto;
  margin-bottom: 20px;
  display: none;

  & button {
    background: none;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
  }

  & .header-search-form {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    border-bottom:1px solid #000;

    & input {
      width: 100%;
      font-size: 1.5rem;
      border: none;
      padding: 0.5rem 0;
      padding-top: 1rem;

      &::placeholder {
        color: #344054;
      }
      
      &:focus {
        box-shadow: none;
      }
    }
  }
}

/*navigation box*/
.mobile-nav {
    @media (max-width:799px) {
        display: block;
    }
    display: none;
    background-color: rgba(255, 255, 255, 0.55);
    padding: 15px 0;

    border-bottom: 1px solid #C7C7C7;
    color: $black-light;
    // font-family: "Poppins", "Arial", sans-serif;
        .header-main-nav {
          max-width: 1410px;
          padding: 0 5rem;
          @media(max-width:799px) {
            padding: 0 2rem;
          }
          margin: 0 auto;
          display: flex;
          gap: 13px;
          align-items: center;
          justify-content: space-between;
          .hamburger-nav {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 30px;
            .hamburger {
              font-size: 30px;
              font-weight: 800;
              .bi-list {
                cursor: pointer;
                -webkit-text-stroke: 1px;
              }
            }
            .bi-search {
              background-color: transparent;
            }
            .hamburger-nav-logo-home {
              img {
                height: 100%;
                width: 20rem;
              }
            }
  
            .hamburger-nav-logo {
              height: 53px;
              @include md {
                height: 45px;
              }
              img {
                height: 100%;
                max-width: 190px;
                object-fit: contain;
              }
              .nav-logo-divider {
                height: 80%;
                width: 3px;
                background-color: black;
                margin-right: 5px;
              }
            }
            a {
              font-size: 16px;
              font-weight: 600;
            }
         }
  
          .authentication {
            display: flex;
            align-items: center;
          }
  
          .login-nav {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 30px;
            font-size: 16px;
            line-height: 20px;
            font-weight: 500;
            a {
              font-size: 18px;
            }
            .login-nav-signin {
              font-size: 16px;
            }
            .login-nav-signin-icon {
              font-size: 20px;
            }
            .login-nav-cart {
              padding-right: 5px;
              cursor: pointer;
              display: block;
              margin: 0;
            }
            .login-nav-accessibility {
              font-size: 14px;
            }
          }
        }
}

.mobile-header-search-container {
  margin-top: 32px;
  padding: 0 5rem;
  display: none;

  & form {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    border-bottom:1px solid #000;
    & img {
      padding-left: 10px;
    }
  }


  & input { 
    width: 100%;
    font-size: 1.5rem;
    border: none;
    padding: 0.5rem 0;
    padding-top: 1rem;
    @media (max-width: 575px) {
       font-size: 16px;
    }
    &::placeholder {
      color: #344054;
    }
    &:focus {
      box-shadow: none;
    }
  }

  & button {
    background: none;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
  }
}

.mobile-menu-right {
  display: flex;
  gap: 16px;
  align-items: center;

  & .search-icon {
    &.active {
      opacity: 0.5;
    }
  }

  & .hamburger {
    width: 30px;
    height: 30px;
    margin-left: 2.4rem;
  }
}