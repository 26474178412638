.gallery-media-block {
  .gallery-media-icons {
    width: 30px;
  }
  .gallery-media-items-container {
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(2, 50fr);
    grid-auto-rows: 60px;
    gap: 10px;

    img,
    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .gallery-media-item {
      display: block;

      &:nth-child(1) {
        grid-column: 1 / 2;
        grid-row: 1 / span 3;

      }

      &:nth-child(2) {
        grid-column: 2 / 3;
        grid-row: 1 / span 2;
      }

      &:nth-child(3) {
        grid-column: 1 / 2;
        grid-row: 4 / span 2;
      }

      &:nth-child(4) {
        grid-column: 2 / 3;
        grid-row: 3 / span 3;
      }

      &:nth-child(5) {
        grid-column: 1 / 2;
        grid-row: 6 / span 3;
      }

      &:nth-child(6) {
        grid-column: 2 / 3;
        grid-row: 6 / span 2;
      }

      &:nth-child(7) {
        grid-column: 1 / 2;
        grid-row: 9 / span 2;
      }

      &:nth-child(8) {
        grid-column: 2 / 3;
        grid-row: 8 / span 3;
      }
    }
  }

  @media (min-width: 576px) {
    .gallery-media-items-container {
      grid-auto-rows: 170px;
      margin-top: 20px;
      gap: 20px;
    }
  }
  @media (min-width: 992px) {
    .gallery-media-items-container {
      grid-template-columns: repeat(3, 33.333333fr);

      .gallery-media-item {

        &:nth-child(1) {
          grid-column: 1 / 2;
          grid-row: 1 / span 3;

        }

        &:nth-child(2) {
          grid-column: 2 / 3;
          grid-row: 1 / span 2;
        }

        &:nth-child(3) {
          grid-column: 3 / 4;
          grid-row: 1 / span 3;
        }

        &:nth-child(4) {
          grid-column: 1 / 2;
          grid-row: 4 / span 2;
        }

        &:nth-child(5) {
          grid-column: 2 / 3;
          grid-row: 3 / span 3;
        }

        &:nth-child(6) {
          grid-column: 3 / 4;
          grid-row: 4 / span 2;
        }

        &:nth-child(7) {
          grid-column: 1 / 3;
          grid-row: 6 / span 2;
        }

        &:nth-child(8) {
          grid-column: 3 / 4;
          grid-row: 6 / span 2;
        }
      }
    }
  }
}

