@use '@material/textfield/mixins';

.contact-form {
  max-width: 600px;
  gap: initial;

  input,
  select,
  textarea {
    background: #ffffff;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    &::placeholder {
      color: #afb1b6;
      font-size: 14px;
      line-height: 28px;
    }
  }
}

.mdc-text-field {
  @include mixins.ink-color(#000);
  @include mixins.focused-outline-color(#000);
  @include mixins.label-color(#000);
  @include mixins.caret-color(#000);
}

.contact-form-fields {
  input[type="file"] {
    border: none;
    padding-left: 0;
  }
  select {
    padding: 14px 14px;
  }
  input[type="date"],
  input[type="datetime-local"] {
    color: #ffffff;
    padding: 12px 10px !important;
    position: relative;
    &::before {
      content: attr(placeholder);
      position: absolute;
      color: black;
    }
    &:focus,
    &:valid {
      color: black;
    }
    &:focus::before,
    &:valid::before {
      content: "";
      display: none;
    }
    &::-webkit-calendar-picker-indicator {
      height: 25px;
      width: 25px;
      opacity: 0.4;
      cursor: pointer;
    }
  }
}

.form-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  padding: 15px !important;
  border-radius: 5px !important;
  img {
    margin-left: 5px;
  }
}

.contact-form-fields-file {
  label {
    margin: 12px 10px 12px 0;
    background-color: #dedede;
    border: none;
    border-radius: 5px;
    padding: 16px 25px;
    box-shadow: 0 2px 4px rgba(175, 177, 182, 0.5);
    cursor: pointer;
  }
}

input[type="date"],
input[type="datetime-local"] {
  color: #ffffff;
  padding: 12px 10px !important;
  position: relative;
  &::before {
    content: attr(placeholder);
    position: absolute;
    color: black;
  }
  &:focus,
  &:valid {
    color: black;
  }
  &:focus::before,
  &:valid::before {
    // content: "";
    display: none;
  }
  &::-webkit-calendar-picker-indicator {
    height: 25px;
    width: 25px;
    opacity: 0.4;
    cursor: pointer;
  }
}

.mdc-text-field {
  width: 100% !important;
}
.mdc-floating-label {
  line-height: inherit !important ;
}


.radio-group,
.checkbox-group {
  margin: 0 0 30px 0;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  font-size: 16px;
  label {
    font-weight: 400;
  }
}
.checkbox-group {
  input[type="checkbox"] {
    &:checked ~ .checkmark {
      background-color: #000000;
      border: 1px solid #000000;
    }
    &:checked ~ .checkmark:after {
      display: block;
    }
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkbox-input-wrap {
    padding-left: 22px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    width: 16px;
    height: 16px;
    background: #ffffff;
    border: 1px solid #afb1b6;
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 4px;
      top: 2px;
      width: 5px;
      height: 8px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
.form-radio-input {
  display: none;
}
.form-label-radio {
  font-size: 14px;
  cursor: pointer;
  position: relative;
  padding-left: 30px;
  font-weight: 400;
}
.checkbox-label-text {
  font-size: 14px;
  cursor: pointer;
  position: relative;
  padding-left: 30px;
  top: -2px;
}

.form-radio-button {
  height: 19px;
  width: 19px;
  border: 1px solid #afb1b6;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
}
.form-radio-button::after {
  content: "";
  display: block;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
  opacity: 0;
  transition: opacity 0.2s;
}
.form-radio-input:checked ~ .form-label-radio .form-radio-button::after {
  opacity: 1;
}

.select-form-group {
  margin: -12px 0 30px 0 !important;
}
.select-label {
  font-size: 13px;
  font-weight: 400;
}

.ss-main {
  border: 1px solid #a8aaae !important;
  padding: 21px 16px !important;

  &:focus {
    border: 2px solid #000 !important;
    box-shadow: none !important;
  }
}
.ss-placeholder {
  color: black !important;
  font-size: 14px !important;
  padding: 0 !important;
}
.ss-option:hover,
.ss-selected,
.ss-value,
.ss-value-text {
  background-color: #dedede !important;
  color: black !important;
  font-size: 14px !important;
  border-radius: 0 !important;
}

.ss-value-delete {
  border-left: none !important;
  svg path {
    stroke: black !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text{
  font-size: 1.5rem ;
}

.add-file-label {
  margin: 12px 10px 24px 0;
  background-color: #dedede;
  border: none;
  border-radius: 5px;
  padding: 16px 25px;
  box-shadow: 0 2px 4px rgba(175, 177, 182, 0.5);
  cursor: pointer;
}