.pg-profile {
  border-top: 1px solid #333;

  .card {
    height: auto;
  }

  .pg-body {
    input[type="checkbox"], label {
      cursor: pointer;
    }

    .col-left {
      display: flex;
      flex-direction: column;
      @media(min-width:768px){
        border-right: 1px solid #bcbcbc;
      }
      padding-bottom: 100px;

      .col-body {
        ul {
          list-style: none;
          margin: 0;
          padding: 24px 15px 0 0;
          display: flex;
          flex-direction: column;
          gap: 4px;

          li {
            a {
              padding: 10px 8px;
              border-radius: 4px;
              width: 100%;
              display: flex;
            }
            &:hover {
              a {
                background: #ccc;
              }
            }

            &.active {
              a {
                background: black;
                color: white;
              }
            }
          }
        }
        .title {
          padding: 8px 0;
          font-size: 16px;
          font-weight: bold;
          border-bottom: 1px solid black;
        }
      }
    }
    .col-right {
      padding-top: 30px;
      padding-left: 16px;
      padding-bottom: 100px;

      .prefs-cont-unit {
        display: flex;
        gap: 8px
      }

      strong {
        margin-bottom: 8px;
        display: block;
        font-size: 20px;
      }
      .col-header {
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;

        & h1 {
          font-size: 2rem;
          font-weight: bold;
        }
        
        @media(max-width:768px){
          flex-direction: column;
        }
      }
    }

    .select2-selection--single {
      border-radius: 0;
      font-size: 16px;
      height: 50px;
      width: 100%;
      max-width: 400px;
      border: 1px solid #333;
      padding: 8px;
      margin: 0;
    }

    .select2-dropdown {
      box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px !important;
    }

    form {
      margin: initial;

      input[type="text"],
      input[type="email"],
      input[type="phone"],
      input[type="password"],
      input[type="date"],
      select {
        font-size: 16px;
        width: 100%;
        max-width: 400px;
        margin: 0;
      }

      .form-check {
        padding: 0;
        margin: 0 0 0 22px;
        display: flex;
        gap: 8px;
        align-items: center;

        .form-check-label {
          margin-top: 0;
          padding-top: 5px;
        }
      }

      .form-group {
        label {
          margin-top: 16px;
        }
      }
    }

    .content-box {
      background-color: #F1F1F1;
      padding: 24px;
      max-width: 600px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: 24px;

      .content-box-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        strong {
          font-size: 18px;
          margin-bottom: 0;
        }
        .edit-btn {
          display: flex;
          font-weight: bold;
          gap: 8px;
          cursor: pointer;
        }
      }
      .col-title {
        margin-bottom: 16px;
        font-weight: bold;
      }
      .col-content {

      }

      .row {
        border-top: 1px solid #ccc;
        padding-top: 16px;
      }
    }
  }

  h3 {
    font-size: 20px;
  }

  .controllers {
    display: flex;
    align-items: baseline;
    gap: 8px;

    .txt-label-title {
      font-weight: 600;
      margin-bottom: 8px;
    }
  }

  .btn {
    background-color: black;
    border: 0;
    color: white;
    height: 50px;
    gap: 8px;
    font-size: 12px;
    font-weight: 500;
  }

  .buttons-area {
    display: flex;
    justify-content: center;
    border-top: 1px solid black;
    padding-top: 8px;

    .btn {
      background-color: transparent;
      color: black;
      font-size: 14px;
      font-weight: 500;
      padding: 0;
      display: flex;
      border: 0;
      gap: 8px;
    }
  }

  .card {
    margin-bottom: 16px;

    .card-body {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .card-text {
        overflow: initial;
        display: initial;
      }

      .card-brand {
        font-size: 18px;
      }
      .card-prod-model-name {
        font-size: 22px;
        font-weight: 600;
        line-height: 25px;
      }
      .card-prod-zep,
      .card-purchase-date {
        font-size: 20px;
        small {
          font-size: 14px;
        }
      }
    }
  }


  @media (max-width: 767px) {
    .pg-body {
      .col-body {
        width: 100%;
      }
    }
  }
}
#pg-profile-loader {
  background-color: rgba(0 0 0 / 90%);
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 16px;
  font-weight: bold;
  z-index: 100;

  &.show {
    display: flex;
  }
}