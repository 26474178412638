//our brand top

/* animations */
@keyframes slideInFromRight {
  from {
    margin-left: 100%;
  }

  to {
    margin-left: 0%;
  }
}

.brand-card-col {
  height: 720px;
  cursor: pointer;
  transition: width 0.4s;
  border: none;

  &:nth-child(2n-1) {
    background: #dbdbdb;
  }

  &:nth-child(2n) {
    background: #f1f1f1;
  }

  &:hover {
    .brand-card-bg-img {
      img {
        opacity: 1;
      }
      video {
        opacity: 1;
      }

      .brand-card-img-overlay-bottom {
        opacity: 1;
      }
    }

    .overlay-top {
      align-items: flex-start;
    }
  }
}

.brand-card-bg-img {
  background-color: inherit;
  border-radius: 0;
  height: 720px;
  position: relative;
  width: 40vw;
  animation: slideInFromRight 0.4s;
  z-index: 1;

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    overflow-x: hidden;
    transition: opacity 0.4s;
  }
}

.brand-card-logo {
  z-index: 1;
  height: auto;
  width: fit-content;
  margin-top: 20px;
  max-width: 7vw;

  img {
    width: -webkit-fill-available;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.5s;
  }
}

.brand-card-img-overlay-bottom {
  opacity: 0;
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  position: absolute;
  bottom: 40px;
  left: 44%;
  transform: translate(-50%, -50%);
  transition: opacity 0.4s;

  .button {
    min-width: 156px;
    &.btn-lg {
      padding: 10px 24px 10px 24px;
    }
  }
}

//our brand individual sections

.brand-section-col-wrap .row {
  &:nth-child(odd) .brand-section-col {
    .brand-card {
      background: #dbdbdb;
      border: none;
    }
  }

  &:nth-child(even) .brand-section-col {
    .brand-card {
      background: #f1f1f1;
      border: none;
    }
  }
}
.brand-section-col {
  .brand-card {
    border-width: 0 !important;
  }
}

.card-img-overlay {
  display: flex;
  flex-direction: column;
  gap: 50px;
  max-width: 50vw;

  &.overlay-top {
    justify-content: start;
    align-items: center;
    transition: align-items 3s;
  }

  &.overlay-left {
    justify-content: center;
    align-items: flex-start;
    padding-left: 5%;
  }

  &.overlay-right {
    justify-content: center;
    align-items: flex-end;
    padding-right: 5%;
    left: auto;
    width: -webkit-fill-available;
    padding-left: 0.8%;
  }

  &.overlay-center {
    justify-content: center;
    align-items: center;
    padding: 5%;
  }
}

.brand-section-bg-img {
  height: 720px;
  @include lg {
    height: 450px;
  }
  border-radius: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.brand-section-card-logo {
  width: 300px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.section-card-heading {
  font-size: 32px;
  line-height: 37.5px;
  font-weight: 600;
}

.section-card-text {
  font-size: 20px;
  line-height: 33px;
  font-weight: 400;
  margin-top: 20px;
}

.brand-section-bottom {
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  width: auto;
  justify-content: space-around;
}

.text-title-section {
  height: 450px;
  max-width: 50vw;
  margin: auto;
}

.brand-heading {
  font-weight: 400;
  font-size: 40px !important;
  line-height: 48px !important;
  letter-spacing: 1.5px !important;
  text-transform: uppercase;
}

.brand-heading-text {
  // font-family: "Poppins", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

// media screens
@include lg {
  .text-title-section {
    max-width: 100vw;
  }

  .card-img-overlay {
    max-width: 100vw;
  }

  .brand-section-bottom {
    width: -webkit-fill-available;
    justify-content: space-around;
    align-self: center;
  }

  .brand-section-card-logo {
    width: 150px;
  }

  .overlay-left {
    justify-content: space-between !important;
    padding: 5%;
  }

  .overlay-right {
    justify-content: space-between !important;
    left: 0 !important;
    padding: 5%;
  }

  .overlay-center {
    justify-content: space-between !important;
  }

  .section-card-heading {
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
  }

  .section-card-text {
    font-size: 16px;
    line-height: 20px;
  }
}

//brand-video
.card.brand-card-col {
  & iframe {
   pointer-events: none;
   height: 100%;
   width: 100%;
   top: 0;
   position: absolute;
  }
}
