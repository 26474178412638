.app-figure {
  width: 85% !important;
  margin: 0px auto;
  padding: 20px;
  text-align: center;
}
.selectors {
  margin-top: 10px;
}
.selectors .mz-thumb img {
  max-width: 56px;
}

.app-code-sample {
  max-width: 80%;
  margin: 30px auto 0;
  text-align: center;
  position: relative;
}

@media screen and (max-width: 1023px) {
  .app-figure {
    width: 98% !important;
    margin: 50px auto;
    padding: 0;
  }
}
@media screen and (max-width: 600px) {
  .mz-thumb img {
    max-width: 39px;
  }
}


// Carousel buttons

.button-wraper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 12px 14px;
    border-radius: 50%;
    border: 1px solid #afb1b6;
  }
  .app-figure {

    .swiper-button-next {
      &::after {
        opacity: 0 !important;
      }
    }
    
    .swiper-button-prev {
      left: 30px !important;
      
      &::after {
        opacity: 0 !important;
      }
    } 
  }
