.home-stat-cards-wrap {
  display: flex !important;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  list-style: none;
  padding: 60px 100px 0 100px;
  max-width: 1320px;
}

.home-stats-controllers {
  display: flex;
  gap: 16px;
  margin-top: 40px;
  justify-content: center;
}

.stats-carousel .owl-item:last-child {
  margin-right: 0 !important;
}

.owl-carousel .owl-stage {
  margin: 0 auto;
}

.stats-btn-wrap {
  display: flex;
  justify-content: center;
  gap: 20px;

  & .prev,
  & .next {
    cursor: pointer;
  }
}

.stats-item p {
  color: #afb1b6;
  margin-top: -5px !important;
}

.owl-nav {
  display: flex;
  justify-content: center;
  font-size: 28px;
  gap: 20px;
  margin-top: 20px;
}

@media (max-width: 500px) {
  .home-stat-cards-wrap .owl-nav {
    justify-content: flex-start;
    padding-left: 57px;
  }
}

.home-stat-card {
  width: 165px;
  text-align: center;
  a {
    .stat-image-container {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      overflow: hidden;
      background-color: #f1f1f1;
      background-image: url("../../../apps/front/static/img/icons/no-image-icon.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 32px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .home-stat-heading {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
    }
    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}
.home-stat-card:not(:last-child) {
  margin-right: 10px;
}
.home-stat-cards-wrap::-webkit-scrollbar {
  height: 0;
}

@media (max-width: 440px) {
  .home-stat-cards-wrap {
    padding: 60px 60px 0 110px;
  }
}

@media (max-width: 400px) {
  .home-stat-cards-wrap {
    padding: 40px 80px 0 97px;
  }
}

@media (max-width: 350px) {
  .home-stat-cards-wrap {
    padding: 40px 45px 0 80px;
  }
}
@media (max-width: 300px) {
  .home-stat-cards-wrap {
    padding: 40px 0px 0 50px;
  }
}
