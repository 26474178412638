.search-filter {
  padding: 34px 15px 0 15px;
  gap: 0;

  .filter-header {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    align-items: center;
    gap: 2px;
    padding: 16px 0;

    #reset-filters {
      border: 0;
      background-color: transparent;
      text-decoration: underline;
      margin-left: auto;
      font-weight: 500;
      cursor: pointer;
    }
  }

  .filter-group {

    .card-header {
      cursor: pointer;
      padding: 16px 8px;
      background-color: transparent;
      display: flex;
      align-items: center;
      font-weight: bold;
      justify-content: space-between;
      border-width: 2px;
      border-color: black;

      &:hover {
        background-color: #f2f2f2;
      }

      h6 {
        margin: 0;
        display: flex;
        align-items: center;
        font-size: initial;
        font-weight: 500;
      }

      .bi-chevron-up {
        display: none;
      }
    }

    .card-body {
      display: none;
      gap: 8px;
      padding: 8px 0 16px;

      input[type="text"] {
        width: 100%;
        padding: 8px;
        border: 1px solid #a0a0a0;
      }

      .desc {
        font-size: 12px;
        font-weight: bold;
        color: #686868;
      }
    }

    &.selected {
      background-color: #f2f2f2;
    }

    &.show {
      background-color: #f2f2f2;

      .bi-chevron-up {
        display: initial;
      }

      .bi-chevron-down {
        display: none;
      }

      .card-body {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #ccc;
      }
    }
  }

  .form-check {
    padding: 0;
    margin: 0;
  }

  .form-check-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    cursor: pointer;

    i {
      display: none;
    }
  }

  .color-wrapper {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .color-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #000;
  }

  .filter-content {
    input[type="checkbox"] {
      display: none;
    }
    input[type="checkbox"]:checked + label {
      background-color: #f2f2f2;
      border-radius: 4px;

      i {
        display: initial;
      }
    }
  }

  @media (max-width: 991px) {
    padding: 0;
    max-width: initial;
    .elem-wrapper {
      display: none;
    }
  }
}

.mobile-filter-button-toggler {
  display: none;
}

.mobile-search-and-prod-counter {
  display: none;
}

@media (max-width: 993px) {
  .mobile-filter-button-toggler {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    h5 {
      font-size: 16px;
      font-weight: 700;
    }
  }
}

@media (max-width: 462px) {
  .mobile-filter-button-toggler {
    flex-direction: column;
  }
}

@media (max-width: 993px) {
  .mobile-search-and-prod-counter {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    font-size: 15px;
    align-items: center;
  }

  @media (max-width: 375px) {
    .display-input {
      display: inline-block;
      position: absolute;
      left: 0;
      margin-top: -14px;

      input {
        width: inherit;
        margin-left: 6px;
      }
      a {
        position: initial !important;
        float: right;
        margin-top: -26px;
        margin-right: 8px;
      }
    }
  }
}

@media (max-width: 991px) {
  .filter-search-wrap {
    padding: 0 25px;
  }
}

.toggle-filters {
  cursor: pointer;
}

.filter-sticky {
  position: sticky;
  position: -webkit-sticky;
  top: -1px;
  overflow: hidden;
  z-index: 9999;
  background-color: #fff;
  height: fit-content;
}

.side-sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 85px;
  z-index: 9999;
  height: fit-content;
  background-color: #fff;
  padding-bottom: 20px;

  &.blog-side-sticky {
    top: 104px;

    @media(max-width:462px){
      top: 190px;
    }
  }

  @media(max-width:462px) {
    top:140px;
  }

  @media(max-width:339px) {
    top:150px;
  }

  @media (max-width: 991px) {
    display: none;
  }
}

@media (min-width: 992px) {
 .side-sticky {     
      top: 20px; /* Adjust for spacing from the top */
  }
}