.newsletter-modal-container {
    justify-content: center;
}
#newsletter-popup {

    .modal-content {
        width: 600px;
        border: none;
    }

}

.newsletter-consent-modal-content {
    display: flex;
    height: 100%;
    & .newsletter-image-container {
        & .desktop {
            display: block;
            width: 200px;
            height: 100%;
            object-fit: cover;
        }
        & .mobile {
            display: none;
        }
    }

    & .form-container {
        flex: 1;
        padding: 20px 0;
        & form {
            gap: 0;
            margin: 0;
            padding: 2rem 6rem;
            text-align: center;
            max-width: 100%;
         
            & .title {
                font-size: 3rem;
                margin-bottom: 2.5rem;

                &.small {
                    font-size: 1.6rem;
                    line-height: 2.5rem;
                }

                &.medium {
                    font-size: 2rem;
                    line-height: 3.5rem;
                }

                &.large {
                    font-size: 3rem;
                    line-height: 4rem;
                }
            }
            & .incentive-text {
                font-weight: bold;
                font-size: 3rem;
                line-height: 4rem !important;
                margin-bottom: 5rem;

                &.small {
                    font-size: 1.6rem;
                    line-height: 2.5rem;
                }

                &.medium {
                    font-size: 2rem;
                    line-height: 3.5rem;
                }

                &.large {
                    font-size: 3rem;
                    line-height: 4rem;
                }
            }

            & input {
                border: 1px solid #D0D5DD;
                padding: 1rem;
                margin-bottom: 16px;
            }

            & button {
                color: #fff;
                border: none;
                padding: 10px;
                width: 100%;
                font-size: 1.8rem;
                line-height: 2.2rem;
            }
        }
    }

    & .btn-close {
        position: absolute;
        top: 15px;
        right: 15px;
        background-color: #fff;
        opacity: 0.8;
    }
}

.newsletter-consent-container {
    text-align: left;
    display: flex;
    line-height: 20px;
    gap: 10px;
    color: #fff;

    & a {
        color: #fff;
    }
}


@media (min-width:451px){
    .hide-first-name-desktop {
        display: none;
    }
    
    .hide-last-name-desktop {
        display: none;
    }
}




@media (max-width:450px) {
    .newsletter-consent-modal-content {
        flex-direction: column;
        & .newsletter-image-container {
            & .desktop {
                display: none;
            }

            & .mobile {
                display: inline;
                height: 165px;
                width: 100%;
            }
            width: 100%;
            text-align: center;
        }

    }

    .hide-last-name-mobile {
        display: none;
    }

    .hide-first-name-mobile {
        display: none;
    }
}