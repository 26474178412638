.product-list-card {
  list-style: none;
  width: 316px;
  scroll-snap-align: start;
  transition: all 0.2s;
  display: block;

  & .label-new {
    position: absolute;
    top: 1px;
    left: 1px;
    background-color: #000000;
    padding: 7px 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    color: #fff;

    @media (max-width: 768px) {
      font-size: 12px;
      padding: 3px 6px;
    }
  }

  .product-card-img-wrapper {
    position: relative;

    .card-btn {
      position: absolute;
      top: calc(50%);
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      transition: all 0.2s linear;
      opacity: 0;
      width: 210px;

      .add-to-cart-btn {
        max-width: 220px;
      }

      @media(max-width: 768px) {
        display: none;
      }
    }

    &:hover {
      @media(min-width: 769px) {
        .card-img-top {
          filter: blur(1px) grayscale(70%);
          background: rgba(0, 0, 0, 0.20);
        }

        .card-btn {
          opacity: 1;
        }
      }
    }
  }

  .product-card-img-contianer {
    display: block;
    background-color: #F8F8F8;
    border: 1px solid #DEDEDE;

    & .card-img-top {
      object-fit: contain;
      min-height: 320px;
      @media(max-width: 768px) {
        min-height: 156px;
      }
    }
  }

  .product-card-body {
    background-color: #ffffff;
    padding: 16px 0;

    &__brand {
      margin-bottom: 8px;
      color: #343344;
    }

    &__logo {
      width: auto !important;
      height: auto;
      max-height: 40px;
      margin-bottom: 16px;
      @media(max-width:1150px){
        max-height: 32px;
      }
      @media(max-width: 768px){
        max-height: 28px;
      }
      @media(max-width: 400px){
        max-height: 25px;
      }
      @media(max-width: 310px){
        max-height: 22px;
      }
    }

    &__title {
      margin-bottom: 8px;
      font-size: 2rem;
      color: #343344;
      font-weight: 700;
      display: block;
      line-height: 2.8rem;
    }

    &__subtitle {
      font-size: 1.6rem;
      color: #343344;
      margin-bottom: 24px;
    }

    &__sku {
      color: #343344;
      font-size: 1.6rem;
      margin-bottom: 16px;
    }

    &__price {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .saved-money-text {
        margin-bottom: 0;
      }

      .original-price {
        font-weight: 700;
        margin-bottom: 8px;
      }
    }

    &__rating {
      display: flex;
      flex-wrap: wrap;
    }

    &__text {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      align-items: center;
    }

    & .bottom-info {
      display: flex;
      color: #343344;
      justify-content: space-between;
      align-items: flex-end;
      //flex-wrap: wrap;
      margin-bottom: 8px;

      .rating {
        display: flex;

        & img {
          margin: 0;
        }
      }
    }
  }

}

.card-btn {
  height: 80px;
  display: flex;
  align-items: flex-end;
}

.add-to-cart-btn {
  margin: 0 auto;
}

.view-product-btn {
  font-size: 18px;
  display: block;
  background-color: #000;
  font-family: "Roboto", sans-serif;
  color: #fff;
  text-align: center;
  padding: 16px 0;
  font-weight: 400;
  max-width: 280px;
  margin: 10px auto 0 auto;
  width: 100%;

  &:hover {
    background-color: #2b2b2b;
    color: #fff;
  }
}

.products-ui-container-wrapper {
  border-left: 1px solid #dbdbdb;

  @media (max-width: 991px) {
    border: 0;
  }
}


.product-carousel-cards-wrap .product-list-card {
  width: 316px;
}

.product-grid-container .product-list-card {
  max-width: 316px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swatch-colors {
  display: flex;
  gap: 4px;
  margin-bottom: 16px;
}

.swatch-color {
  cursor: pointer;

  & img {
    display: block;
    border-radius: 50%;
    height: 31px;
    width: 31px !important;
    margin-top: 2px;
    margin-left: 2px;
    border: 1px solid #a2a2a2;
  }

  &.active {
    & img {
      height: 30px;
      width: 30px !important;
      border: 2px solid #FF3E3E;
    }
  }
}

#bv-rating {
  display: flex;
  align-items: center;
}

.pdp-swatch {
  .swatch-colors {
    display: flex;
    gap: 16px;
    @media(max-width: 550px) {
      gap: 8px;
    }
  }

  .swatch-color {
    cursor: pointer;

    & img {
      display: block;
      border-radius: 50%;
      height: 62px;
      width: 62px !important;
      margin-top: 2px;
      margin-left: 2px;
      outline: 1px solid #A2A2A2;
      @media(max-width: 550px) {
        height: 48px;
        width: 48px !important;
      }
    }

    &.active {
      position: relative;

      &::before {
        content: "";
        background-image: url("../../../apps/front/static/img/icons/slected-icon.svg");
        background-repeat: no-repeat;
        position: absolute;
        right: 0;
        bottom: -5px;
        width: 25px;
        height: 25px;

      }

      & img {
        outline: 2px solid #212529;
        border: none;
      }
    }
  }
}

.label-new {
  position: absolute;
  top: 0;
  background-color: #000000;
  padding: 2px 6px;
  font-size: 12px;
  color: #fff;
  z-index: 999;
}

.discontinued-product {
  border: 1px solid #333;
  box-sizing: border-box;
  padding: 10px;
  margin-top: 5.5rem;
}
