.top-header-wraper {
    position: relative;
    background-color: #F1F1F1;
}
.top-header {
    max-width: 1410px;
    width: 100%;
    padding: 1.6rem 3rem;
    @media (max-width: 799px) {
      padding: 1.6rem 1.5rem;
    }
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & .top-header-brands__brand-logo {
       & img {
          width: 40px !important;
          height: 40px;
          object-fit: cover;
          filter: brightness(0.5);
      }

      &.False { 
        display: none;
      }
    } 
}

.header-brands-left {
  width: fit-content;
  @media(max-width: 550px) {
    width: 220px;
  }
  @media(max-width: 450px) {
    width: 160px;
  }

  @media(max-width: 371px) {
    width: 100px;
  }
  max-width: 700px;
  position: relative;

  @media (max-width: 799px) {
    max-width: 100%;
    width: 100%;
  }

  & .top-header-brands__brand-logo {
    & img {
      @media (max-width: 799px) {
      height: 32px !important;
      width: 32px !important;
      }
      @media (max-width: 575px) {
        height: 22px !important;
        width: 22px !important;
      }
    }
  }

 & .owl-nav {
  margin-top: 10px;
  position: absolute;
  & img {
    height: 12px;
    width: 8px;
   }
 }

  & .owl-carousel .owl-stage {
    margin: 0;

    @media (max-width: 799px) {
      width: 100% !important;
      .owl-item {
        margin-right: 16px !important;
      }
    }
  }
}

.header-menu-auxilary {
  display: flex;
  gap: 16px;
  @media (max-width:799px){
    gap: 8px;
    margin-left: 8px;
  }

  & .auxilary-nav {
    line-height: 14px;

    &__item {
      font-size: 10px;
      line-height: normal;
      margin-right: 8px;

      &.False {
        display: none;
      }
    }
  }
}

.header-auxilary-right {
  display: flex;
  align-items: center;
  gap: 16px;
  width: fit-content !important;
  @media (max-width: 799px) {
    gap: 7px;
    display: none;
  }
}

.login-nav-language {
    font-weight: 400;
    font-size: 16px;
    display: flex;
    align-items: center;
    background: none !important;
    gap: 8px;
    .language-country-flag {
      font-size: 12px;
      font-weight: 600;
      display: inline-flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      img {
        width: auto;
        height: 18px;
        margin-right: 8px;
      }
    }
    .bi-chevron-down {
      -webkit-text-stroke: 1px;
    }
  }
  .dropdown-menu {
    --bs-dropdown-padding-y: 0 !important;
    .menu-item {
      margin-bottom: 5px;
      .sub-menu-heading {
        padding: 5px 15px;
        font-size: 14px;
        font-weight: bold;
      }
      .sub-menu-divider {
        height: 1px;
        width: -webkit-fill-available;
        background-color: $grey-for-divider;
      }
      .sub-menu {
        list-style-type: none;
        .sub-menu-item {
          margin-top: 5px;
          margin-left: -20px;
          a {
            padding-left: 25px;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
  }