//@font-face {
//  font-family: myFirstFont1;
//  src: url('../fonts/NokaTrial-Bold-BF63bccdec13b50.otf');
//}

.lander_early_access {
  display: flex;
  min-height: 100vh;
  width: 100%;

  .row {
    height: 100%;
  }

  .col-left {
    background-size: cover;
    background-position: center center;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    .col-body {
      max-width: 400px;
      gap: 30px;
      display: flex;
      flex-direction: column;

      p {
        font-size: 16px;

        strong {
          font-size: 24px;
        }
        margin: 0;
      }
    }

    .body-title {
      h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 100px;
        line-height: 100px;
      }
      span {
        font-size: 11rem;
        position: absolute;
        margin-left: -45px;
        margin-top: 45px;
      }
    }

    .form-element-wrapper {
      display: flex;
      gap: 16px;
      margin: 0;
      flex-direction: row;

      input {
        color: black;
        background-color: transparent;
        height: 48px;
        border: 1px solid black;
        text-align: center;
        border-radius: 24px;
        font-size: 16px;

        &::placeholder {
          color: black;
        }
      }
      button {
        color: white;
        background-color: black;
        min-width: 48px;
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        border-radius: 50%;
      }
    }
  }
  .landing-logo-link {
      margin: 10px auto;
  }

  @media (min-width: 992px) {
    .col-right {
      .col-body {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
      }
    }
    .col-body-cont {
      max-width: 432px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      img {
        max-width: 300px;
        max-height: 7.5vh;
      }
    }
  }

  @media (max-width: 992px) {
    .col-left {
      .col-body {
        padding: 50px
      }
      .body-title {
        h1 {
          font-size: 70px;
          line-height: 67px;
        }

        span {
          font-size: 9rem;
          position: absolute;
          margin-left: -40px;
          margin-top: 25px;
        }
      }
    }

    .col-right {
      .col-body-cont {
        columns: 2;
        margin: 50px auto;
        max-width: 400px;

        img {
          display: list-item;
          width: 80%;
          margin: 0 10%;
        }
      }
    }
  }
}