

.grouped-category-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
}

.img-square-trending{
    flex-basis: 25%;
    position:relative;
}

.grouped-category-text {
    font-size: 3rem;
}

@media (max-width:1130px){
    .img-square-trending{
        flex-basis:50%;
        position:relative;
    }
}

@media (max-width:600px){
    .img-square-trending{
        flex-basis:100%;
        position:relative;
    }
}