body {
  font-family: "Poppins", "Arial", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  overflow-y: auto !important;
}

input,
textarea,
select {
  outline: none;
  font-family: inherit;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
  font-weight: 600;
}

h1 {
  font-size: 32px;
  line-height: 38px;
}

h2 {
  font-size: 30px;
  line-height: 35px;
}

h3 {
  font-size: 28px;
  line-height: 33px;
}

h4 {
  font-size: 24px;
  line-height: 28px;
}

h5 {
  font-size: 20px;
  line-height: 23px;
}

p {
}

a {
  cursor: pointer;
  color: $black-light;
  text-decoration: none;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  &:hover {
    color: $black-light;
  }
}

.font-20 {
  font-size: 20px !important;
}
.font-16 {
  font-size: 16px !important;
}

.rich-text-container {
  //max-width: 900px;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;

  a {
    text-decoration: underline;
    &:hover {
      opacity: 75%;
    }
  }
  h1,
  h2,
  h3,
  h4 {
    margin-top: 60px;
  }
}
.rich-text-container > *:first-child {
  margin-top: initial;
}
