.shop-by-brand-wrap {
  display: flex !important;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  list-style: none;
  padding: 0 100px 0 100px;
  max-width: 1320px;
}
.shop-by-brand-wrap::-webkit-scrollbar {
  height: 0;
}

.shop-by-brand-card {
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #F0EEE8;
    text-align: center;

    .brand-image-container {
        width: 100px;

        & img {
            height: 100%;
            width: 100%;
        }
    }
}


@media (max-width: 400px) {
  .shop-by-brand-wrap {
    padding: 40px 80px 0 97px;
  }
}

@media (max-width: 350px) {
  .shop-by-brand-wrap {
    padding: 40px 0px 0 80px;
  }
}
@media (max-width: 300px) {
  .shop-by-brand-wrap {
    padding: 40px 0px 0 50px;
  }
}
