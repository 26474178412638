.tab-section {
  ul {
    display: flex;
    gap: 25px;
    justify-content: center;
    border-bottom: 1px solid $gris-clair;

    .nav-link {
      color: $blue-piv;
      font-size: 12pt;

      &.active {
        color: $blue-moyen;
        font-weight: bold;
        border-bottom: 3px solid $blue-moyen;
      }
    }
  }

  .tab-content {
    padding-top: 25px;
    margin-bottom: 50px;
  }
}
