
.section-loading.dark {
  background-color: #333;
}

.card-skelton .skeleton {
    background-color: whitesmoke;
    overflow: hidden;
    position: relative;
}

.card-skelton .skeleton::before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to right, transparent, transparent, #d7d7d7, transparent, transparent);
    transform: translate(-100%);
    animation: loading 1.2s infinite;
}

@keyframes loading {
  100% {
    transform: translate(100%);
  }
}

.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 450px;
}
.card-container .card-skelton {
  border: 1px solid #d7d7d7;
  min-height: 450px;
  width: 100%;
}
.card-container .card-img {
  min-height: 240px;
}

.card-container .card-title {
  min-height: 15px;
  margin: 5px 10px;
}
.card-container .card-title.second{
  min-height: 50px;
  margin: 5px 10px;
}
.card-container .card-title.first {
  width: 60%;
  margin-top: 20px
}
.card-container .card-title.second {
  width: 85%;
  margin-top: 50px;
}
.card-container .card-title.third {
  width: 40%;
  margin-top: 40px;
}

.card-container .card-button {
  width: 50%;
  height: 50px;
  margin: auto;
  margin-bottom: 15px;
  border-radius: 5px;
  border-radius: 50px;
}