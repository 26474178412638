.tab-content-wraper {
  padding-top: 4.8rem;
  padding-bottom: 2.4rem;
  background-color: $gris-50;
}
.tabs-container {
    width: 100%;
    border-top: 1px solid var(--Stroke-grey, #DEDEDE);
    border-bottom: 1px solid var(--Stroke-grey, #DEDEDE);
    overflow-x: scroll;
    background-image: linear-gradient(to right, white, white),
    linear-gradient(to right, white, white),
    linear-gradient(to right, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0)),
    linear-gradient(to left, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0));
    background-position: left center, right center, left center, right center;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;
    background-attachment: local, local, scroll, scroll;
    &::-webkit-scrollbar {
      display: none;
    }
}

.tabs {
    display: block;
    margin: 0 auto;
    width: fit-content;

    &__list{
      margin: 0;
      padding: 0;
      list-style: none;  
      display: flex;
     
      gap:16px;
      position: relative;
      transition: all 0.3s ease-in-out;

      & span {
        height: 2px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        background: #000;
    }
    }

    &__item{
      padding: 1.6rem 0;
      font-size: 1.6rem;
      display: inline-flex;
      flex-direction: column;
      text-transform: uppercase;
      white-space: nowrap;
      cursor: pointer;
        &.active {
          position: relative;
          font-weight: bold;
        }

        &::after {
          content: attr(data-text);
          height: 0;
          visibility: hidden;
          overflow: hidden;
          user-select: none;
          pointer-events: none;
          font-weight: bold;
        
          @media speech {
            display: none;
          }
      }
    }
}

.tab-item-content {

  &.active {
    display: block; /* Display active tab content */
  }

  & .tag-line {
    border-bottom: 1px solid #1D2739;
    padding: 1.6rem 0;
  }

  & .paragraph {
    margin-bottom: 32px;
  }
}

#tab-description {
  & .first-row {
    max-width: 800px;
    margin: 0 auto;
  }

  & .second-row {
    max-width: 800px;
    margin: 0 auto;
  }

  & .second-row {
    & .heading-tertiary {
      border-bottom:1px solid #000;
      margin-bottom: 16px;
    }
  }

  & .video-container {
    margin-bottom: 32px;
    & video {
      width: 100%;
      aspect-ratio: 16/9;
    }

  }
}

#tab-spec {
  max-width: 800px;
  margin: 0 auto;
  & .spec-title {
    margin-bottom: 1.6rem;
    border-bottom: 1px solid #1D2739;
    padding-bottom: 1.6rem;
  }


  & .benefits-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @include respond(md) {
      grid-template-columns: repeat(1, 1fr);
    }
    gap: 32px;
    padding-top: 24px;
  }
}

#tab-replacement-parts {
  max-width: 800px;
  margin: 0 auto;

  & .diagram-img {
    width: 100%;
    margin-bottom: 32px;
  }
  & .table-head {
    display: grid;
    text-align: center;
    grid-template-columns: 1fr 1.5fr 2fr 2fr;
    column-gap: 20px;
    border-bottom: 2px solid #2D2D2D;
    
    & span {
      font-size: 16px;
      padding: 16px 0;
      font-weight: 500;
    }

  }

  & .table-body {
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 1.5fr 2fr 2fr;
    column-gap: 20px;


    & .column-content {
      font-size: 16px;
      margin: 16px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;


    }
  }


  & iframe {
    height: 76px !important;
    margin-top: -20px;
  }


 & .add-to-cart-container {
  margin: 0 auto;
  width: fit-content;
  margin-bottom: 10px;
 }

  
 & .view-product-container {
  width: 100%;
  margin-bottom: 12px;
 }
}


#tab-documentation {
  width: fit-content;
  margin: 0 auto;

  & .documentation-list {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 16px;

    & li {
      display: flex;
      align-items: center;

      & a {
        font-size: 18px;
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset:3px;
      }
    }
  }
}

.feature-box {
  display: flex;
  flex-direction: column;
  max-width: 272px;
  padding-bottom: 16.6rem;

  & img {
    object-fit: cover;
    display: block;
    margin-bottom: 13px;
    width:272px;
    aspect-ratio:1/1;
    
  }

  & .heading-quaternary {
    font-weight: 700;
    line-height: 25px;
  }

  & .paragraph {
    font-weight: 600;
    line-height: 22px;
    font-size: 12px;
  }
}

// carousel style 
.owl-four {
  position: relative;

  & .owl-nav {
    position: absolute;
    right: 0;
    bottom: 4.9rem;
    margin: 0;
    gap: 15px;

    & button {
      height: 41px;
      width: 41px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: #fff !important;
      filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.15));
    }
  }
}


@media(max-width: 550px) {

  .tab-content-wraper {
    background-color: #fff;
  }

  #tab-replacement-parts {

    & .table-head {
      display: none;
    }
   & .table-body {
      grid-template-columns: 1fr;


      & .column-content {
        justify-content: space-between;
        & span {
          font-weight: 500;
        }

        &.underline{
          border-bottom: 2px solid #2D2D2D;
        }
      }
      & .column-content.title-column {
        flex-direction: column;
        gap: 16px;
      }

      & .item {
        border-bottom: 2px solid #2D2D2D;
      }
    }

  }


  #tab-documentation {
    & .documentation-list {
      & li {  
        & a {
          font-size: 16px;
        }
      }
    }
  }
}

@media(min-width:551px) {
  .column-content {
    & span {
      display: none;
    }
  }

}


* {
  // border: 1px solid red;
}