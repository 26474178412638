.column-block-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.6rem;

    @include respond(md) {
        grid-template-columns: repeat(2, 1fr) !important;
    }

    @include respond(sm) {
        grid-template-columns: repeat(2, 1fr) !important;
    }
}

.column-block-title {
    padding-top: 4rem;
    padding-bottom: 2rem;

    &.center {
        text-align: center;
    }

    &.left {
        text-align: left;
    }

    &.right {
        text-align: right;
    }
}

.column-block {
    display: block;
    margin-bottom: 4rem;
    padding-bottom: 1rem;
    position: relative;
    transition: all .3s ease;

    &.zoom:hover {
        box-shadow: 0 2.4rem 4rem 0 rgba(0, 0, 0, .25);
        scale: 1.05;
        .column-block__img.active {
            filter: grayscale(1) brightness(3);
        }
    }

    &:hover  {
        .column-block__img-layer {
            opacity: 1;
        }
    }


    &__img-layer {
        position: absolute;
        opacity: 0;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        transition: .3s ease all;
    }
    
    &__img-wrapper {
        position: relative;
    }

    &__img {
        transition: all 0.6s ease;
        display: block;
        height: auto;
        width: 100%;
        object-fit: cover;
        aspect-ratio: 1/1;
        margin-bottom: 1.5rem;
    }

    &__title {
        padding: 0 1.5rem;
        font-size: 2.2rem;
        line-height: 3rem;
        font-weight: 600;
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;

        & p {
            font-size: 2.2rem;
            font-weight: 600;
            margin-bottom: 1.5rem;
        }

        & h2 {
            font-size: 2.2rem;
            font-weight: 600;
            margin-bottom: 1.5rem;
        }

        & h3 {
            font-size: 2rem;
            font-weight: 600;
            margin-bottom: 1.5rem;
        }

        & h4 {
            font-size: 1.8rem;
            font-weight: 600;
            margin-bottom: 1.5rem;
        }

        &.center {
            text-align: center;
        }

        &.left {
            text-align: left;
        }

        &.right {
            text-align: right;
        }
    }

    &__text {
        padding: 0 1.5rem;
        font-size: 1.6rem;
        margin-bottom: 2.5rem;
        & h2 {
            font-size: 2.2rem;
            font-weight: 600;
            margin-bottom: 1.5rem;
        }

        & h3 {
            font-size: 2rem;
            font-weight: 600;
            margin-bottom: 1.5rem;
        }

        & h4 {
            font-size: 1.8rem;
            font-weight: 600;
            margin-bottom: 1.5rem;
        }

        &.center {
            text-align: center;
        }

        &.left {
            text-align: left;
        }

        &.right {
            text-align: right;
        }
    }

    &__btn {
        border: none;
        padding: 1rem 2rem;
        margin-right: auto;
        display: block;
        transition: all .3s;
        border-radius: 50rem;
        font-size: 1.6rem;
        @media (max-width:500px) {
            font-size: 1.5rem;
        }
        line-height:2rem;
        width: 80%;
        margin: 0 auto;

        &:hover {
            filter: opacity(70%);
        }

        &.square {
            border-radius: 0;
        }
        &.right {
            margin-right: 1.5rem;
            margin-left: auto;
        }

        &.left {
            margin-right: auto;
            margin-left: 1.5rem;
        }

        &.center {
            margin-left: auto;
        }

    }
}