.main-header-wrap {
  .main-header {
    z-index: 10;

    /*top message box*/
    .header-message-box {
      // font-family: "Poppins", "Arial", sans-serif;
      padding: 5px 0;
      color: $white-dark;
      .header-message {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        P {
          margin-bottom: 0 !important;
          font-size: inherit;
        }
        a {
          color: $white;
          font-size: inherit;
          text-decoration: underline;
        }
        .header-message-close {
          position: absolute;
          right: 5px;
          font-size: 20px;
          cursor: pointer;
          &:hover {
            transform: scale(1.5);
            transition: transform 0.3s ease-in-out;
          }
        }
      }
    }

    /*top brand logo box*/
    .header-brand-logo-box {
      background-color: #f0eee8;
      color: white;
      // font-family: "Poppins", "Arial", sans-serif;
      font-weight: 500;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 60px;

      .header-brand-logo {
        padding: 0 5rem;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        scroll-snap-align: start;
        white-space: nowrap;
        display: flex;
        align-items: center;
        &::-webkit-scrollbar {
          display: none;
        }
        font-size: 14px;
        @media only screen and (min-width: 993px) {
          display: flex;
          align-items: center;
          gap: 40px;
        }

        & .home-logo {

          & img {
            width: 85px;
            filter: brightness(0.5);
          }

          &  img:hover{
            filter: brightness(0) !important;
          }
        } 



        .brand-logo {
          padding: 6px 10px;
          display: inline-flex;
          align-items: center;
          @media only screen and (min-width: 993px) {
            padding: 0;
          }

          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
          img {
            max-height: 25px;
            filter: brightness(0.5);
            &:hover {
              filter: brightness(0);
            }
          }
        }
      }
    }

    .header-brand-logo-box-nav-btn-wrap {
      margin-top: 6px;
      justify-content: center;
      gap: 24px;
      color: red;
      .logo-scroll-left,
      .logo-scroll-right {
        cursor: pointer;
        filter: brightness(1);
        z-index: 100;
        .bi {
          -webkit-text-stroke: 1px;
        }
      }

      .logo-scroll-left.active {
        filter: brightness(0);
      }
      .logo-scroll-right.active {
        filter: brightness(0);
      }
    }

    /* brand logo menu toggle */
    .brand-logo-display {
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      gap: 5px !important;
      position: absolute;
      overflow: auto;
      left: 0;
      background-color: #000000;
      min-width: 100vw;
      z-index: 100;
      padding: 3vw 25vw;
    }

    /*thin line*/
    .header-thin-divider {
      height: 1px;
      width: -web-kit-fill-available;
      background-color: $grey-for-divider;
    }
    /*navigation box*/
    .header-main-nav-box {
      background-color: rgba(255, 255, 255, 0.55);
      margin: 15px 0;
      color: $black-light;
      // font-family: "Poppins", "Arial", sans-serif;
      .header-main-nav {
        max-width: 1410px;
        padding: 0 5rem;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .hamburger-nav {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 30px;
          .hamburger {
            font-size: 30px;
            font-weight: 800;
            .bi-list {
              cursor: pointer;
              -webkit-text-stroke: 1px;
            }
          }
          .bi-search {
            background-color: transparent;
          }
          .hamburger-nav-logo-home {
            img {
              height: 100%;
              width: 200px;
            }
          }

          .hamburger-nav-logo {
            height: 53px;
            @include md {
              height: 45px;
            }
            img {
              height: 100%;
            }
            .nav-logo-divider {
              height: 80%;
              width: 3px;
              background-color: black;
              margin-right: 5px;
            }
          }
          a {
            font-size: 16px;
            font-weight: 600;
          }
        }

        .authentication {
          display: flex;
          align-items: center;
        }

        .login-nav {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 30px;
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
          a {
            font-size: 18px;
          }
          .login-nav-signin {
            font-size: 16px;
          }
          .login-nav-signin-icon {
            font-size: 20px;
          }
          .login-nav-cart {
            padding-right: 5px;
            cursor: pointer;
            display: block;
            margin: 0;
          }
          .login-nav-accessibility {
            font-size: 14px;
          }
        }
      }
    }
  }

  /*sidebar modal*/
  .offcanvas {
    --bs-offcanvas-width: 450px;
    background-clip: border-box;
    padding: 25px;
    transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
  }

  /*sidebar modal view-1 (main nav)*/

  .offcanvas-header {
    .offcanvas-title {
      width: 170px;

      a {
        img {
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }
    }

    .cart-navbar.shopify-cart-btn {
      margin-left: 10px;
    }

    .offcanvas-search-close {
      display:flex;
      a {
        color: $grey-light-2;
        font-size: 18px;
        margin-left: 10px;
        &:hover {
          color: $black-light;
          transform: scale(1.2);
          transition: transform 0.3s ease-in-out;
        }
      }

      & .sidebar-icons {
        margin-right: 30px;
        display: flex;
      }

      .bi-search,
      .bi-x-lg {
        -webkit-text-stroke: 1px;
      }

      .offcanvas-search-icon {
        display: none;
      }
    }
  }

  .offcanvas-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;

    &::-webkit-scrollbar {
      display: none !important;
      width: 0 !important;
    }

    .sidebar-top {
      margin-top: 76px;
   
      & a p {
        font-weight: 600;
        margin-bottom: 20px;
      }
      .sidebar-signin {
        display: flex !important;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        font-size: 22px;
        line-height: 20px;
        font-weight: 500;
        padding: 25px 0;
        border-bottom: 2px solid $grey-light;

        .login-nav-signin {
          font-size: 16px;
          i {
            font-size: 20px;
          }
        }
      }

      .sidebar-nav {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 15px;

        a {
          font-size: 18px;
          line-height: 32px;
          font-weight: 600;
        }

        .sidebar-sub-menu-text {
          margin-top: 10px;
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          padding-left: 12px;
        }
      }

      .sidebar-contact {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 15px;

        a {
          font-weight: 500;
        }
      }
    }

    .sidebar-bottom {
      gap: 20px;

      .sidebar-news-letter {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        p {
          color: $black-light;
          text-decoration: none;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;

          label{
            width: 100%;
          }
          
          input {
            padding: 10px;
            border: 1.5px solid $grey-light;
            width: 100%;
            color: $black-light;
            cursor: text;
          }

          input::-webkit-input-placeholder {
            // font-family: "Poppins", "Arial", sans-serif;
            font-size: 14px;
          }

          a {
            color: $grey-light;
            padding-left: 25px;
            font-size: 25px;

            &:hover {
              color: $grey-light-2;
              transform: scale(1.2);
              transition: transform 0.3s ease-in-out;
            }
          }
        }
      }

      .sidebar-language-accessibility {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 40px;

        .sidebar-language {
          font-weight: 600;

          .bi-chevron-down {
            -webkit-text-stroke: 1px;
          }
        }

        .login-nav-accessibility {
          cursor: pointer;
          text-decoration: none;
          color: $black-light;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
        }
      }
    }
  }

  /* sidebar modal view-2 (shop) */
  .offcanvas-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .bi-arrow-left-short {
      -webkit-text-stroke: 1px;
    }
  }

  .sidebar-product-recommender {
    gap: 20px;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 2px solid $grey-light;

    a {
      font-weight: 500;

      img {
        width: 20px;
      }
    }
  }

  /* sidebar modal view-2 (shop) */
  .sidebar-search-product-heading {
    padding: 15px 0 15px 15px;
    text-align: start;
    font-size: 18px;
    font-weight: 600;
    color: $grey-light;
  }

  .sidebar-search-product {
    cursor: pointer;
    text-decoration: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;

    img {
      height: 110px;
      width: -webkit-fill-available;
      border: 1.5px solid $grey-light;
    }

    p {
      color: $black-light;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .header-search-form {
    display: flex;
    flex-direction: row;

    input {
      display: initial;
      border: none;
      background-color: #f1f1f1;
      border-radius: 25px;
      padding: 6px 10px;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      width: 16vw;
    }
    button {
      margin-left: -45px;
      background-color: #f1f1f1;
      border-radius: 0 25px 25px 0;
      border: 0;
      background-color: transparent;
    }
  }
}

/*media screens*/
@include lg {
  /*top brand logo box*/
  .header-brand-logo-toggle {
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 14px;
  }

  /* sidebar modal view-1 (main nav) */
  .offcanvas-search-icon {
    display: block !important;
  }
}


/*hero section*/
.plp-hero {
  height: 300px;
  position: relative;
  .hero-background-image {
    background: linear-gradient(120deg, #000000 0%, #141515 100%);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 300px;
    filter: brightness(0.8);
    img {
      opacity: 0.7;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .hero-headline {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    h1 {
      font-family: 'Geologica', sans-serif;
      line-height: 10rem;
      font-size: 10rem;
      font-weight: 900;
      max-width: 465px;
    }
    p {
      line-height: 3rem;
      font-size: 2rem;
      max-width: 480px;
      padding-top: 3.5rem;
    }
    .hero-button {
      font-size: 2rem;
      line-height: 2.4rem;
      padding: 1rem 2.4rem;
      width: max-content;
      border-radius: 100px;
      i {
        font-size: 4rem;
      }
    }
  }
}

.header-hero-section {
  color: $black-light;
  position: relative;
  height: 600px;

  .hero-background-image {
    background: linear-gradient(120deg, #000000 0%, #141515 100%);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 600px;
    filter: brightness(0.8);
    img {
      opacity: 0.7;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .hero-headline {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    h1 {
      font-family: 'Geologica', sans-serif;
      line-height: 10rem;
      font-size: 10rem;
      font-weight: 900;
      max-width: 465px;
    }
    p {
      line-height: 3rem;
      font-size: 2rem;
      max-width: 480px;
      padding-top: 3.5rem;
    }
    .hero-button {
      font-size: 2rem;
      line-height: 2.4rem;
      padding: 1rem 2.4rem;
      width: max-content;
      border-radius: 100px;
      i {
        font-size: 4rem;
      }
    }
  }
}


@include xl {
  /* navigation box*/
  .login-nav {
    .login-nav-cart {
      margin-right: 20px;
    }
    .login-nav-accessibility {
      display: none !important;
    }
    .login-nav-language {
      display: none !important;
    }
  }
}

@include lg {
  .header-hero-section {
    .hero-headline {
      h1 {
        line-height: 80px;
        font-size: 80px;
        font-weight: 600;
        max-width: 355px;
      }
      p {
        max-width: 255px;
        padding-top: 15px;
      }
    }
  }
}

@include md {

  .header-hero-section {
    .hero-headline {
      h1 {
        line-height: 50px;
        font-size: 50px;
        font-weight: 600;
        max-width: 255px;
      }
      p {
        max-width: 255px;
        padding-top: 15px;
      }
    }
  }
}

@include respond(sm) {
  .main-header-wrap .main-header .header-brand-logo-box .header-brand-logo {
    padding: 0 3rem;
  }
  .main-header-wrap .main-header .header-main-nav-box .header-main-nav {
    padding: 0 3rem;
  }

  .main-header-wrap .main-header .header-main-nav-box .header-main-nav .hamburger-nav .hamburger-nav-logo-home img {
    width: 180px;
  }

  .login-nav-signin {
    display: none;
  }
  .login-nav {
    gap: 0 !important;
  }
  .sidebar-top .login-nav-signin {
    display: block;
  }
}


@include respond(xs) {

  .main-header-wrap .main-header .header-main-nav-box .header-main-nav .hamburger-nav .hamburger-nav-logo-home img {
    width: 140px;
  }
}



@media (max-width: 400px) {
  .hamburger-nav-logo {
    height: 35px !important;
  }
}
