.skeleton-loader {
  background-color: #f7f7f7;
  border-radius: 1px;
  animation: skeleton-loading 1.2s infinite linear;
}

@keyframes skeleton-loading {
  0% {
    background-color: #dadada;
  }
  50% {
    background-color: #f7f7f7;
  }
  100% {
    background-color: #dadada;
  }
}
