.form-input-date {
    border-radius: 5px;
    padding: 20px 10px !important;
    border: 1px solid #A8A8A8;
    font-family: 'Roboto',sans-serif ;
    font-weight: 400;

    &::placeholder {
        color: #000 !important;
        font-size: 15px;
        font-family: 'Roboto',sans-serif ;
    }
}

.error-message{
    color: #dc3545;
    & span{
        font-size: 1.4rem !important;
      }
}

.consent-checkbox-text {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #000 !important;
    top: 0 !important;
    padding-left: 0 !important;

    & a {
        font-size: 14px !important;
        text-decoration: underline;
    }
}