#tooltip[data-show] {
    display: block;
  }
#tooltip {
  display: none;
  background: #333;
  color: white;
  font-weight: bold;
  padding: 8px;
  padding-top: 16px;
  font-size: 13px;
  border-radius: 4px;
  max-width: 240px;
  text-align: center;
  position: relative;
  z-index: 99999999999999999 !important;


  & img {
    width: 220px;
  }

  & p {
    font-size: 12px !important;
    font-weight: 400;
  }
}

#arrow,
#arrow::before {
position: absolute;
width: 8px;
height: 8px;
background: inherit;
}

#arrow {
visibility: hidden;
}

#arrow::before {
visibility: visible;
content: '';
transform: rotate(45deg);
}

#tooltip[data-popper-placement^='top'] > #arrow {
bottom: -4px;
}

#tooltip[data-popper-placement^='bottom'] > #arrow {
top: -4px;
}

#tooltip[data-popper-placement^='left'] > #arrow {
right: -4px;
}

#tooltip[data-popper-placement^='right'] > #arrow {
left: -4px;
}

.image-info {
    position: absolute;
    right: -45px;
    top: 50%;
    transform: translate(-50%,-50%);

    @media(max-width:460px) {
      top: -15px;
      right: 0;
    }
  }