.modal-content {
    padding: 0 ;
    & .dropdown-list {
        max-height: 190px !important;
    }
}



.modal-header {
    border-bottom: 0;

    & .btn-close {
        opacity: 0.8;
    }
}

.modal-body {
    padding: 0 35px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
    .country-img-container {
        height: 40px;
        & img {
            width: 75px;
        }
    }

    .modal-text {
        margin: 20px 0 35px 0;
    }

    .consent-text {
        line-height: 2.5rem;
        & a {
            text-decoration: underline;
        }
    }
}

.modal-footer {
    border-top: 0;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;

    & .dropdown {
        margin-top: 10px;
        width: 100%;
    }

    & .dropdown-list {
        max-height: 280px !important;
    }


    & button{
        border-radius: 5px;
        width: 50%;
    }
}

#languages-selector {
    appearance: none !important;
    background-color: #f4f4f4 !important;
    background-position: right 10px center !important;
    background-image: url("../../../apps/front/static/img/icons/select-icon.svg");
    background-repeat: no-repeat !important;
    background-size: auto !important;
    border-radius: 2px !important;
    border: 0 !important;
    color: #333 !important;
    cursor: pointer !important;
    display: inline-block !important;
    font-size: 16px !important;
    height: auto !important;
    line-height: 1.375 !important;
    margin: 0.3em !important;
    max-width: 100% !important;
    min-height: unset !important;
    min-width: unset !important;
    padding: 0.3em 20px 0.3em 0.5em !important;
    text-indent: 0.01px !important;
    vertical-align: baseline !important;
    width: auto !important;
    background-color: transparent !important;
    border: 1px solid #babfc3 !important;
    border-radius: 4px !important;
    width: 100% !important;
    height: 38px !important;
    margin: 0 !important;
    padding-left: 40px !important;
}

.selector-wrapper--flag {
    background-repeat: no-repeat;
    background-position: 11px center;
    background-size: 25px;
    background-color: #f4f4f4 !important;
    border-radius: 4px !important;
    width: 100%;
}
