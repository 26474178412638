.label-input-container {
    & label {
        display: flex;
        gap: 5px;
        flex-direction: column;

        & span {
            padding-left: 16px;
        }

        & input {
            padding: 15px;
            border-radius: 5px;
            outline: none;
            border: 1px solid #a8aaae;
        }

    }
}