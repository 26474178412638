/*hero section*/
.home-image-headline {
  background-color: rgba(255, 255, 255, 0.55);
  color: $black-light;
  position: relative;
  width: -webkit-fill-available;
  object-fit: cover;
  min-height: 400px;
  height: 500px;

  .home-image-headline-bg-img {
    min-height: 100%;
    height: 100%;
    background-color: #f1f1f1;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

  }

  .home-image-headline-text {
    position: absolute;
    top: 50%;
    left: 50%;
    &.text-right-left {
      transform: translate(-50%, -50%);
    }
    &.text-bottom {
      transform: translate(-50%, 50%);
    }
    p {
      line-height: 24px;
      font-size: 20px;
    }
    .home-image-headline-btn {
      text-transform: uppercase;
      font-size: 20px;
      line-height: 24px;
      padding: 2px 45px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: max-content;
      i {
        font-size: 40px;
      }
    }
  }
}
.home-headline-underline {
  background-color: $black-light;
  width: 65px;
  height: 3px;
  margin-top: 20px;
  @media (max-width:1300px) {
    margin-top: 10px;
  }
}
.home-view-all-blogs-link {
  text-transform: uppercase;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  i {
    font-size: 30px;
  }
}

/* shop item & offers section */

.home-shop-card {
  background-color: $grey-white;
  color: $black-light;
  position: relative;
  width: -webkit-fill-available;
  object-fit: cover;
  height: 500px;
  @media (max-width: 575px) {
    height: 350px;
  }

  .home-shop-card-bg-img {
    width: -webkit-fill-available;
    object-fit: cover;
    position: absolute;
    background-color: #454545; //dark-grey
    background-color: #f1f1f1;
    background-image: url("../../../apps/front/static/img/icons/no-image-icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .home-card-headline-text {
    padding: 30px;
    z-index: 2;
    &.product-bottom {
      transform: translate(-50%, 30%);
    }
    &.offers-bottom {
    }
    h2 {
      line-height: 32px;
      font-size: 28px;
      font-weight: 600;
    }
    p {
      line-height: 24px;
      font-size: 20px;

    }
    .home-card-headline-btn {
      text-transform: uppercase;
      font-size: 16px;
      line-height: 20px;
      padding: 10px 24px 10px 36px;
      font-weight: 500;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: max-content;
      border-radius: 100px;
      @media (max-width:1300px) {
        font-size: 16px;
        line-height: 15px;
        padding: 8px 22px 8px 34px;
      }

      i {
        font-size: 20px;
      }
    }
  }
}

/*Contact with us image gallery */
.gallery-container {
  display: grid;
  grid-template-columns: repeat(12, auto), 1fr;
  grid-template-rows: repeat(18, 60px);
  grid-gap: 20px;
  max-height: 1080px;
  overflow: hidden !important;

  a {
    img,
    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

@include lg {
  .text-right {
    transform: translate(-50%, -50%) !important;
  }

  .home-image-headline-text {
    h1 {
      line-height: 24px;
      font-size: 28px;
      font-weight: 400;
    }
    .home-image-headline-btn {
      padding: 2px 35px !important;
      font-size: 16px !important;
      line-height: 20px !important;
      i {
        font-size: 30px !important;
      }
    }
  }
  .home-image-headline-text {
    h1 {
      line-height: 24px;
      font-size: 22px;
      font-weight: 400;
    }
    .home-image-headline-btn {
      font-size: 14px !important;
      line-height: 20px !important;
      i {
        font-size: 30px !important;
      }
    }
  }
}

.locally-map-wrap {

  h2 {
    padding: 40px 0;
  }

  iframe {
    max-height: 600px;
  }
  .locally-map {
    max-height: 600px;
  }
  a {
    display: none !important;
  }
}
