.single-video-container {
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
  }
  
  .single-video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .single-video-container iframe::-webkit-media-controls {
    display:none !important;
  }
  .single-video-container iframe::-webkit-media-controls-start-playback-button {
    display:none !important;
  }


.video-container {
  display: block;
  position: relative;
  aspect-ratio: 16/9;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  margin: 0 auto;

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  & video {
    position: absolute;
    height: 100%;
    width: 100%;
  }
}

.video {
  &__content {
    margin: auto 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 20%;
    height: 60%;
    width: 100%;
    z-index: 3;
  }
  &__text {
    font-family: "Geologica", sans-serif;
    margin-bottom: 3rem;
    display: block;
    align-self: center;

    &.left {
      align-self: flex-start;
      text-align: left;
    }

    &.right {
      align-self: flex-end;
      text-align: right;
    }

    &.center {
      align-self: center;
      text-align: center;
    }

    &.small {
      font-size: 1.6rem;
      line-height: 2.5rem;
        & h1 {
        font-size: 4rem;
        line-height: 4rem;
        font-weight: 900;
      }

      & h2 {
        font-size: 3.5rem;
        font-weight: 900;
        line-height: 3.5rem;
      }

      & h3 {
        font-size: 3.2rem;
        line-height: 3.2rem;
        font-weight: 900;
      }
      & h4 { 
        font-size: 3rem;
        line-height: 3rem;
        font-weight: 900;
      }
      & p {
        font-size: 1.6rem;
        line-height: 2rem;
        padding-top: 2rem;
      }
    }

    &.medium {
      & h1 {
        font-size: 6rem;
        line-height: 6rem;
        font-weight: 900;
      }

      & h2 {
        font-size: 5rem;
        font-weight: 900;
        line-height: 5rem;
      }

      & h3 {
        font-size: 4rem;
        font-weight: 900;
        line-height: 4rem;
      }
      & h4 { 
        font-size: 3rem;
        font-weight: 900;
        line-height: 3rem;
      }
      & p {
        font-size: 2rem;
        line-height: 3rem;
        padding-top: 3.5rem;
      }
      font-size: 2rem;
      line-height: 3.5rem;
    }

    &.large {
      & h1 {
        font-size: 10rem;
        line-height: 10rem;
        font-weight: 900;
      }

      & h2 {
        font-size: 8.4rem;
        font-weight: 900;
        line-height: 8.4rem;
      }

      & h3 {
        font-size: 6rem;
        font-weight: 900;
        line-height: 6rem;
      }

      & h4 {
        font-size: 5rem;
        font-weight: 900;
        line-height: 5rem;
      } 

      & p {
        font-size: 2rem;
        line-height: 3rem;
        padding-top: 3.5rem;
      }

      font-size: 3rem;
      line-height: 4rem;
    }
  }

  &__btn {
    padding: 1rem 2rem;
    display: inline-block;
    align-self: flex-start;
    transition: all 0.2s;
    border-radius: 50rem;
    font-size: 2rem !important;
    line-height: 2rem;
    margin-bottom: 1rem;
    white-space: wrap;

    &:hover {
      filter: contrast(0.7);
    }

    &.circle {
      border-radius: 50rem;
    }
    
    &.square {
      border-radius: 0;
    }
  }

  &__layer {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0.5;
    z-index: 2;
  }
}

.btns_container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;

  &.center {
    justify-content: center;
  }

  &.right {
    justify-content: flex-end;
  }
}




@media (max-width: 991px){
  .video {
    &__text {
      margin-bottom: 1rem;

      & h1 {
        font-size: 6rem !important;
        line-height: 7rem !important;
      }
      & h2,
      h3,
      h4 {
        font-size: 5rem !important;
        line-height: 6rem !important;
      }
      & p {
        font-size: 1.6rem !important;
        line-height: 2.6rem !important;
      }
    }
  }
} 

@include respond(sm) {
  .video {
    &__text {

      & h1, 
      h2,
      h3,
      h4 {
        font-size: 4rem !important;
        line-height: 5rem !important;
      }
      & p {
        font-size: 1.4rem !important;
        line-height: 2rem !important;
        padding-top: 0 !important;
      }
    }
  }
}
  
@include respond(xs){
  .video {
    &__text {

      & h1, 
      h2,
      h3,
      h4 {
        font-size: 2rem !important;
        line-height: 3rem !important;
      }
      & p {
        font-size: 1.4rem !important;
        line-height: 2rem !important;
        padding-top: 0 !important;
      }
    }
  }
} 