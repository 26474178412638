.contact-page {
  .contact-form {
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"],
    input[type="date"],
    input[type="month"],
    input[type="datetime-local"],
    input[type="search"],
    input[type="url"],
    textarea,
    select
    {
      width: 100%;
      padding: 10px;
      border: 1px solid #61646B;
    }
    .form-group {
      margin-bottom: 30px;
      label {
        margin-bottom: 10px;
      }
    }
    .form-check {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 5px;
      .form-check-input {
        margin-bottom: 5px;
        margin-right: 10px;
        &:checked {
          background-color: #000000;
          border-color: #000000;
        }
      }
      .form-check-label {
        margin-right: 10px;
        margin-bottom: initial;
      }
    }
  }
}