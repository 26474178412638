.checkout-btn {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 30px;
  background: #343344;
  color: #FFF;
  text-align: center;
  font-family: Poppins, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
  max-width: 360px;
  border: 0;
  &:hover {
    background-color: #343344;
    color: #FFFFFF;
  }
  &:disabled {
    background-color: #343344;
    color: #fff;
    opacity: 0.5;
  }
}
.checkout-btn-previous {
  font-family: Inter, serif;
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 30px;
  background: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  text-transform: uppercase;
  color: var(--Office-Brown-900, #3E3838);
  font-feature-settings: 'cv04' on, 'cv03' on, 'cv01' on;
  font-weight: 600;
  line-height: 145%;
  border: 1px solid #000;
  background: var(--Grey-0, #FFF);
}
.add-to-cart-container {
  width: 100%;
}
.add-to-cart {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: #343344;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 25%;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
  width: 100%;
  height: 47px;
  font-weight: 300;
  &:hover {
    background-color: #343344;
  }
}

.join-now-btn {
  background-color: #FAE5A2;
  border: none;
  padding: 16px 24px;
  font-size: 16px;
  font-weight: 600;
}

.search-zep-btn-container {
  position: absolute;
  right: 3px;
  top: 50%;
  transform: translateY(-50%);
  & .btn {
    padding: 24px 25px;
  }
}

.loader {
  width: 25px;
  height: 25px;
  border: 1px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}