.owl-five {
    position: relative !important;

    & .image-container {
        display: block;
        @media (max-width:1625px){
            width: 428px;
            height: 518px;
        }
        width: 528px;
        height: 618px;
        border: 1px solid #DEDEDE;

        & .mz-figure {
            background: #F8F8F8;
        }

        & img {
            width:526px;
            height: 618px;
            object-fit: cover;
            @media (max-width:1625px){
                width: 428px;
                height: 518px;
            }
    
        }

    }

}

#pdp-carousel {
  margin-top: 32px;
}
.mobile-carousel {
    display: none;
    max-width: 95%;
    margin: 0 auto;
}

.custom-nav {
    position: relative;
    width: 1632px;
    @media(max-width:1625px){
        width: 1332px;
    }
    margin: 0 auto;

    & button {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 41px;
        width: 41px;
        filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.15));
        background-color: #fff;
        border: none;
        border-radius: 50%;
        top: -329px;
        z-index: 99;
    }

    & .owl-next {
        right: 578px;
    }

    & .owl-prev {
        left: 26px;
    }

}

.merch-code-container {
    position: relative;
    width: 1632px;
    margin: 0 auto;
}

.product-details-container {
    position: relative;
    width: 1632px;
    margin: 0 auto;
}
.product-details {
    padding: 32px 20px;
    position: absolute;
    background-color: #fff;
    z-index: 99;
    border: 1px solid #e5e5e5;
    height: fit-content !important;
    width: 528px;
    right: 0;
    min-height: 618px;
    box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.04), 0px 4px 8px -2px rgba(0, 0, 0, 0.08);
}


/* product card swiper */

.product-card-swiper {
   & .product-swiper-wraper {
        width: fit-content !important;
        margin: 0 auto;
    }

    .swiper-button-next {
        &::after {
          opacity: 0 !important;
        }
    }
      
    .swiper-button-prev {
        left: 30px !important;
        
        &::after {
          opacity: 0 !important;
        }
    } 
}

.title-btns-container {
    display: flex;
    gap: 15px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;

    &.details {
        margin: 0 18px;
        padding-right: 2px;
    }
    @media(max-width: 580px) {
        margin: 0 18px;
        padding-left: 0;
        padding-right: 25px;
    }

}

.btn-wrap {

    & .prev-btn.swiper-button-disabled {
         opacity: 0;
     }

     & .next-btn.swiper-button-disabled {
         opacity: 0;
     }

     &.bottom {
        opacity: 0;
     }

     &.top {

        & button {
            border: none;
            height: 41px;
            width: 41px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background: #fff !important;
            filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.15));
        }

        & .second-prev-btn.swiper-button-disabled {
            opacity: 0;
        }

        & .second-next-btn.swiper-button-disabled {
            opacity: 0;
        }
     }
 }

@media(max-width:1625px) {
    .product-details-container {
        width: 1332px;
    }
    .merch-code-container {
        width: 1332px;
    }

    .product-details {
        @media(max-width:1625px) {
            width: 428px;
            height: 518px;
        }
    }

    .custom-nav {
        & button {
            top: -280px;
        }
        & .owl-next {
            right: 478px;
        }
        & .owl-prev {
            left: 20px;
        }
    }
}


@media(max-width:1440px) {
    .owl-five {
        & .image-container {
            width: 1080px;
            height: 618px;
            & img {
                width: 528px !important;
                height: 618px !important;
            }
            
        }
        & .owl-item.center {
            & .image-container {
                border: none;
                & .mz-figure {
                    width: 528px;
                    height: 618px;
                    border: 1px solid #DEDEDE;

                    & img {
                        width: 528px;
                        height: 618px;
                    }
                }
            }
        }
    }

    .product-details-container {
        width: 1080px;
    }
    .merch-code-container {
        width: 1080px;
    }
    .product-details {
        width: 528px;
        height: 618px;
    }


    .custom-nav {
        width: 1080px;

        & button {
            top: -329px;
        }

        & .owl-next {
            right: 572px;
        }
    
        & .owl-prev {
            left: 20px;
        }
    }
}

@media(max-width:1100px){
    .product-details-container  {
        width: 880px;
    }
    .merch-code-container {
        width: 880px;
    }
    .product-details {
        width: 428px;
        height: 618px;
    }

    .owl-five {
        & .image-container {
            width: 880px;
            height: 518px;
        }

        & .owl-item.center {
            & .image-container {
                border: none;
                & .mz-figure {
                    width: 428px;
                    height: 518px;
                    border: 1px solid #DEDEDE;

                    & img {
                        width: 428px !important;
                        height: 518px !important;
                    }
                }
            }
        }

        & .owl-item {
            & .image-container {
                & img {
                    width: 428px !important;
                    height: 518px !important;
                }
                }
        }
        
    }

    .custom-nav {
        width: 880px;

        & button {
            top: -280px;
        }

        & .owl-next {
            right: 472px;
        }
    
        & .owl-prev {
            left: 20px;
        }
    }

}

@media(max-width:920px) {
    .owl-five {
        & .image-container {
            width: 528px;
            height: 618px;
        }

        & .owl-item.center {
            & .image-container {
                border: none;
                & .mz-figure {
                    width: 528px;
                    height: 618px;
                    border: 1px solid #DEDEDE;

                    & img {
                        width: 528px !important;
                        height: 618px !important;
                    }
                }
            }
        }

        & .owl-item {
            & .image-container {
                border: none;
                & .mz-figure {
                    width: 528px;
                    height: 618px;
                    border: 1px solid #DEDEDE;

                    & img {
                        width: 528px !important;
                        height: 618px !important;
                    }
                }
            }
        }
    }

    #pdp-carousel {
        display: flex;
        flex-direction: column;
        flex-direction: column-reverse;
    }
    .merch-code-container {
        width: 528px;
    }
    .product-details-container {
        width: 100%;
    }
    .product-details {
        position: static;
        width: 100%;
        box-shadow: none;
        border: none;
    }

    .custom-nav {
        width: 100%;

        & button {
            top: -329px;
        }

        & .owl-next {
            right: 50px;
        }
    
        & .owl-prev {
            left: 50px;
        }
    }
}

@media(max-width:550px) {

    .owl-five {
        display: none !important;
    }

    .custom-nav {
        display: none;
    }

    .mobile-carousel {
        display: block;
    }

    .merch-code-container {
        width: calc(100% - 20px);
    }
}

//product details style


.product-details-container, .single-image-product {
    .bv_main_container {
        flex-direction: column;
        gap: 5px;
    }
    
    & [data-bv-show="rating_summary"] {
        margin-bottom: 0;
    }
    & [data-bv-show="rating_summary"] .bv_main_container .bv_button_buttonMinimalist {
        text-decoration: underline !important;
        line-height: 19px !important;
        font-size: 16px !important;
        &:hover {
            text-decoration: underline !important;
            line-height: 19px !important;
            font-size: 16px !important;
        }
    }

    & [data-bv-show="rating_summary"] .bv_main_container .bv_stars_component_container {
        padding-right: 0 !important;
    }

    & [data-bv-show="rating_summary"] .bv_main_container .bv_numReviews_text {
        font-size: 14px !important;
        &:hover {
            font-size: 14px !important;
        }
    }
}

.reviews-sku-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3.2rem;
}



