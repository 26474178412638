.login-page {
  height: 100%;
  background-color: #454545;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  .login-block-container {
    width: 100%;
    max-width: 450px;
    background-color: #FFFFFF;
    padding: 25px;
    margin-top: 45px;
    margin-bottom: 45px;
    @media (max-width: 576px) {
      padding: 25px 12px;
    }
    .login-form {
      max-width: initial;
      gap: initial;

      .login-form-options {
        margin-top: 30px;
       label, a {
         font-weight: 400;
         font-size: 12px;
        }
        a {
          text-decoration: underline;
        }
        .text-danger {
          font-size: 12px;
        }
        input[type='checkbox'] {
          &:checked ~ .checkmark {
            background-color: #000000;
            border: 1px solid #000000;
          }
          &:checked ~ .checkmark:after {
            display: block;
          }
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }
        label {
          padding-left: 22px;
          display: inline-block;
          position: relative;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
        .checkmark {
          position: absolute;
          top: 2px;
          left: 0;
          width: 16px;
          height: 16px;
          background: #FFFFFF;
          border: 1px solid #AFB1B6;
          &:after {
            content: "";
            position: absolute;
            display: none;
            left: 4px;
            top: 2px;
            width: 5px;
            height: 8px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }
    }
  }
  .login-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
    img {
      margin-left: 5px;
    }
  }
  .login-heading {
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    color: #000000;
  }
  .login-subheading {
    margin-top: 15px;
    margin-bottom: 30px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }
}