.article-headline {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    & h1 {
        font-size: 5rem;
        line-height: 50px;
        margin-bottom: 20px;
    }

}