.sidebar-top{
    & .break-line{
        width: 100%;
        height: 1px;
        background: #AFB1B6;
    }
    .accordion {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #444;
        border-bottom: 1px solid #AFB1B6;
        cursor: pointer;
        padding: 16px;
        width: 100%;
        outline: none;
        background-color: white;
        transition: 0.4s;

        &.False {
            display: none;
        }
    
        &.active {
            background: #F1F1F1;
        }
    
        & .icon-down {
            display: none;
        }
    
        &.active .icon-down {
            display: block;
        }
    
        &.active .icon-right {
            display: none;
        }
    
        & .main-menu__nav-link {
            font-size: 20px;
            &:hover {
                font-weight: 600;
            }
        }
    
        &.desktop {
            display: none;
        }
     }

     .panel {
        padding: 0 16px;
        background-color: white;
        display: none;
        overflow: hidden;
        margin-top: 16px;
    
        & .sub-menu-lvl-1 {
            & a {
                color: #3B3B3B;
                line-height: 32px;
                margin-bottom: 8px;
                display: block;
            }

            &.desktop {
                display: none;
            }

            &.False {
                display: none;
            }
        }
    
        & .sub-menu-lvl-1.has-image {
            margin-bottom: 24px;
            & a {
                display: -webkit-box;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                flex-direction: column;
                flex-direction: column-reverse;
                -webkit-box-align: start;
                align-items: flex-start;
                gap: 8px;
                font-size: 10px;
                line-height: normal;
            }
    
    
            & img {
                -webkit-filter: brightness(0);
                filter: brightness(0);
                max-height: 25px;
                -o-object-fit: contain;
                width: 100%;
                text-align: left;
                max-width: 209px;
                width: max-content;
            }
        }
      }
}


  .sidebar-auxilary-container {
    margin-top: 72px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    & a {
        font-size: 14px;
        &.False {
            display: none;
        }
    }
  }