.product-hero-headline {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  & h1 {
    font-size: 5rem;
    line-height: 50px;
    margin-bottom: 20px;
  }
}

.product-grid-container {
  // font-family: "Poppins", "sans-serif";
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
  padding: 0 25px;
}

.total-products-count {
  @media (max-width:462px) {
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .product-grid-container {
    grid-template-columns:repeat(auto-fill, minmax(210px, 1fr));
    padding: 0 15px;
    gap: 15px;
  }
}

@media(max-width: 469px) {
  .product-grid-container {
    grid-template-columns:repeat(auto-fill, minmax(calc(135px), 1fr));
    gap: 20px;
  }
}

@media(max-width: 350px) {
  .product-grid-container {
    grid-template-columns:repeat(auto-fill, minmax(calc(100px), 1fr));
    gap: 15px;
  }
}


.add-to-cart-loading {
  height: 0;
  width: 0;
  position: absolute;
  left: 42%;
  bottom: 15px;
  margin-top: 20px;
  padding: 15px;
  border: 6px solid #ccc;
  border-right-color: #888;
  border-radius: 22px;
  margin: 0 auto;
  animation: rotate 1s infinite linear;
}

@keyframes rotate {
  /* 100% keyframe for  clockwise. 
     use 0% instead for anticlockwise */
  100% {
    -webkit-transform: rotate(360deg);
  }
}
