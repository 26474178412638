%btn-one {
  background-color: $blue-moyen;
  display: inline-flex;
  padding: 15px 25px;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;

  &.md {
    font-size: 12pt;

    img {
      width: 15px;
    }
  }

  .icon-w {
    display: none;
  }

  &:hover {
    .icon-w {
      display: inline-block;
    }

    .icon {
      display: none;
    }

    background-color: $blue-piv;
    color: white;
  }

  @media (max-width: $screen-md-min) {
    font-size: 12pt;
  }
}

.btn {
  @extend %btn-one;
}

.btn-link {
  @extend %btn-one;
}

.btn-trans {
  @extend %btn-one;

  background-color: transparent;
  border: 1.5px solid $blue-moyen;
  padding: 10px 15px;
  color: $blue-moyen;
  display: inline-flex;
  gap: 10px;
  align-items: center;

  &:hover {
    border-color: transparent;
    background-color: $blue-piv;
  }
}

/*pelican button*/

.button {
  cursor: pointer;
  display: inline-block;
  // font-family: 'Poppins', 'Arial', sans-serif;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out, border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out, transform 0.1s ease-in-out;
  &.btn-regular {
    font-weight: 500;
    padding: 0.6rem 1rem;
    font-size: 1.6rem;
    line-height: 2rem;
    border-radius: 1rem;
  }
  &.btn-lg {
    font-weight: 400;
    padding: 1rem 2.4rem;
    font-size: 1.6rem;
    line-height: 2rem;
    text-transform: uppercase;
  }
  &.btn-tag {
    font-weight: 400;
    padding: 4px 8px;
    font-size: 14px;
    line-height: 1.6rem;
    border-radius: 1.5rem;
  }
  &.btn-search {
    font-weight: 400;
    padding: 5px 10px;
    font-size: 16px;
    line-height: 20px;
    border-radius: 4px;
  }
  &.btn-bright {
    color: $black-light;
    background-color: $white;
    border: 1.5px solid $white;
  }
  &.btn-dark {
    color: $white;
    //background-color: black;
    border: 1.5px solid black;
  }
  &.btn-grey {
    color: $grey-light-2;
    background-color: $white;
    border: 1.5px solid $white;
  }
  &.btn-dark {
    color: $white;
    background-color: black;
    border: 1.5px solid black;

    &:disabled {
      background-color: #7a7a7a !important;
      border-color: #7a7a7a !important;
      color: #fff !important;
      cursor: not-allowed;
    }
  }
  &.btn-dark-border {
    color: $white;
    background-color: black;
    border: 1.5px solid black;
  }
  &.btn-bright-border {
    color: black;
    background-color: white;
    border: 1.5px solid black;
  }
   &.btn-transparent-border {
    color: black;
    background-color: transparent;
    border: 1.5px solid black;
  }

  &:hover { 
    background-color: #252525 !important;
    border-color: #252525 !important;
    color: #fff !important;
  }
}

.email-btn {
  background-color: transparent;
  margin: 0;
  border: 0;
  background: #000;
  padding: 9.5px;
}