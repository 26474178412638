.auth {
  display: flex;
  max-width: 450px;
  padding: 25px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  &__title {
    color: #000;
    font-family: Poppins, serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
  }
  form {
    width: 100%;
    max-width: 100%;
    input {
      width: 100%;
      height: 56px;
      border: 1px solid #D0D5DD;
      border-radius: 6px;
      color: #344054;
      font-family: Roboto;
      font-weight: 400;
      font-size: 14px;
      line-height: 20.3px;
      padding: 0 16px;
    }

    .login-reset-password {
      color: #343434;
      font-family: Poppins, serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-decoration-line: underline;
    }
    .check-remember-me-label {
      color: #212529;
      font-family: Poppins, serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
      padding-left: 22px;
      display: inline-block;
      position: relative;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
    }
    .form-check-input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    .remember-me-label {
      background-color: #000;
      position: absolute;
      top: 2px;
      left: 0;
      width: 16px;
      height: 16px;
      border: 1px solid #afb1b6;
      &:after {
        content: "";
        position: absolute;
        left: 4px;
        top: 2px;
        width: 5px;
        height: 8px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        display: block;
      }
    }
    button {
      align-self: center;
      width: 100%;
    }
  }

}