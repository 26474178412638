.home-stat-nav-btn-wrap {
  position: absolute;
  top: 50px;
  right: 10px;
}

.product-scroll-left,
.product-scroll-right {
  cursor: pointer;
  .bi {
    -webkit-text-stroke: 1px;
  }
  &:hover {
    transform: scale(1.5);
    transition: transform 0.3s ease-in-out;
  }
}
.product-carousel-cards-wrap {
  justify-content: center;
  list-style: none;
  overflow-x: scroll;
  overflow-y: hidden;
  margin: 0 auto;
  &.snap-x-mandatory {
    scroll-snap-type: x mandatory;
  }
}

.home-product-carousel-card:not(:last-child) {
  margin-right: 10px;
}
.product-carousel-cards-wrap::-webkit-scrollbar {
  height: 0;
}


.btn-wrap {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.next-btn {
  cursor: pointer;
}
.prev-btn {
  cursor: pointer;
}

.owl-theme .owl-nav .disabled,
img.disabled {
  opacity: 0 !important;
}

@media (max-width: 400px) {
  .home-product-carousel-card {
    width: 300px;
  }
}

@media (max-width: 300px) {
  .home-product-carousel-card {
    width: 270px;
  }
}
