.alert-container {
  max-width: 1410px;
  padding: 0 5rem;
  margin: 0 auto;
  width: 100%;
}
.alert{
    margin:20px auto;
    padding:16px;
    padding-right: 35px;
    position:relative;
    border-radius:5px;
    display: flex;
    gap: 2.6rem;

    & p {
      margin: 0;
    }
  }
  .close{
    position:absolute;
    width:20px;
    height:20px;
    border-width:1px;
    right:16px;
    top:50%;
    transform: translateY(-50%);
    text-align:center;
    font-size:1.6em;
    color: #6c6c6c;
    cursor:pointer;
  }
  @mixin alert($name,$bgColor){
    $accentColor:darken($bgColor,75);
    .#{$name}{
      background-color:#{$bgColor};
      color: $accentColor;
    }
  }
 
  @include alert(success-alert,#dcf2ea);
  @include alert(danger-alert,#ffdede);
 