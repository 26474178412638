.summary-container {
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 28px;
  background-color: #F9FAFB;
  border: 1px solid #D0D5DD;
  hr {
    border-color: #E4E7EC;
    opacity: 1;
  }
  &__header {
    &__title {
      font-family: Inter, serif;
      font-size: 24px;
      font-weight: 600;
      line-height: 28.8px;
      letter-spacing: -2%;
      color: #101928;
    }
    &__count {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: #000000;
      font-family: Inter, serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 21.1px;
      text-align: center;
      color: #FFFFFF;
    }
  }


  &__items {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    &__empty{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #212529;
      font-family: Inter, serif;
      img {
        width: 50px;
        height: 50px;
      }

    }
  }
  .summary-line-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    &__content {
      width: 100%;
      &__product-details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        &__title{
          color: #212529;
          font-family: Inter, serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        &__price {
          color: #101928;
          font-family: Inter, serif;
          font-size: 18px;
          font-weight: 600;
          line-height: 26.1px;
          &__currency {
            font-family: Inter, serif;
            font-size: 11px;
            font-weight: 700;
            line-height: 15.95px;
            color: #101928;
            vertical-align: super;
          }
        }
      }
    }

    &__image {
      width: 100px;
      height: 100px;
      border: 1px solid #D0D5DD;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #FFFFFF;
      img {
        width: 91.47px;
        height: 91.47px;
      }
    }
  }
  .pricing-info-container {
    gap: 24px;
    display: flex;
    width: 100%;
    flex-direction: column;
    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      &__information {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        &__label {
          color: #475367;
          font-feature-settings: 'cv04' on, 'cv03' on;
          font-family: Inter, serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 145%;
        }
        &__value {
          color: #475367;
          text-align: right;
          font-family: Inter,serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 145%;
          &__subtotal {
            color: #475367;
            text-align: right;
            font-family: Inter,serif;
            font-size: 14px;
            font-style: normal;
            line-height: 145%;
            font-weight: 700;
          }
          &__total {
            color: var(--Grey-800, #1D2739);
            text-align: right;
            font-feature-settings: 'cv04' on, 'cv03' on, 'cv01' on;
            font-family: Inter, serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 145%;
          }
        }
      }
    }
  }
}
