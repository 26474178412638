.signup-container {
  background-color: #f1f1f1;
  display: flex;
  max-width: 450px;
  padding: 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  &__title{
    color: #000000;
    font-family: Poppins, serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
  }
  &__help {
    color: #000000;
    font-family: Poppins, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
  }

  a {
    align-self: center;
    width: 100%;
  }
}
.auth-options {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.guest {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 26.1px;
    color : #101928;
  }
  a {
    color: #000000;
    font-family: Poppins, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
    text-decoration-line: underline;
  }
}