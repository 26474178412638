.single-image-block {
  .single-image-container {
    max-height: 635px;
    overflow-y: hidden;
    img {
      width: 100%;
      height: auto;
      border-radius: 5px;
    }
  }
}