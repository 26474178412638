
@mixin respond($breakpoint){
  
    @if $breakpoint == xs{
        @media (max-width:400px){ 
            @content;
        }
       }
     
       @if $breakpoint == sm{
        @media (max-width:600px){ 
            @content;
        }
       }
    
       @if $breakpoint == md {
           @media (max-width:920px){ @content ;} 
    
       }
    
       @if $breakpoint == lg {
    
        @media (max-width:1100px){ @content ;} 
    
       }

       @if $breakpoint == xl {
    
        @media (max-width:1200px){ @content ;} 
    
       }

}