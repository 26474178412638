/** Source: https://codepen.io/reiinii1/pen/aPGXEa **/

[data-tooltip] {
  --arrow-size: 5px;

  position: relative;
  z-index: 10;
}

[data-tooltip]::before,
[data-tooltip]::after {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  left: 50%;
  bottom: calc(100% + var(--arrow-size));
  pointer-events: none;
  transition: 0.2s;
  will-change: transform;
}

[data-tooltip]::before {
  content: attr(data-tooltip);
  padding: 10px 18px;
  min-width: 50px;
  max-width: 300px;
  width: max-content;
  border-radius: 6px;
  font-size: 14px;
  background-color: rgba(59 72 80 / 90%);
  background-image: linear-gradient(30deg, rgba(59 72 80 / 44%), rgba(59 68 75 / 44%), rgba(60 82 88 / 44%));
  box-shadow: 0 0 24px rgba(0 0 50 / 20%);
  color: white;
  text-align: center;
  white-space: pre-wrap;
  transform: translate(-50%, calc(0px - var(--arrow-size))) scale(0.5);
}

[data-tooltip]::after {
  content: "";
  border-style: solid;
  border-width: var(--arrow-size) var(--arrow-size) 0 var(--arrow-size); /* CSS triangle */
  border-color: rgba(55 64 70 / 90%) transparent transparent transparent;
  transition-duration: 0s;
  transform-origin: top;
  transform: translateX(-50%) scaleY(0);
}

[data-tooltip]:hover::before,
[data-tooltip]:hover::after {
  visibility: visible;
  opacity: 1;
}

[data-tooltip]:hover::before {
  transition-delay: 0.3s;
  transform: translate(-50%, calc(0 - var(--arrow-size))) scale(1);
}

[data-tooltip]:hover::after {
  transition-delay: 0.5s;
  transition-duration: 0.2s;
  transform: translateX(-50%) scaleY(1);
}

/* LEFT */
[data-tooltip-location="left"]::before,
[data-tooltip-location="left"]::after {
  left: auto;
  right: calc(100% + var(--arrow-size));
  bottom: 50%;
}

/* Tooltip */
[data-tooltip-location="left"]::before {
  transform: translate(calc(0px - var(--arrow-size)), 50%) scale(0.5);
}

[data-tooltip-location="left"]:hover::before {
  transform: translate(calc(0px - var(--arrow-size)), 50%) scale(1);
}

/* Arrow */
[data-tooltip-location="left"]::after {
  border-width: var(--arrow-size) 0 var(--arrow-size) var(--arrow-size);
  border-color: transparent transparent transparent rgba(55 64 70 / 90%);
  transform-origin: left;
  transform: translateY(50%) scaleX(0);
}

[data-tooltip-location="left"]:hover::after {
  transform: translateY(50%) scaleX(1);
}

/* RIGHT */
[data-tooltip-location="right"]::before,
[data-tooltip-location="right"]::after {
  left: calc(100% + var(--arrow-size));
  bottom: 50%;
}

[data-tooltip-location="right"]::before {
  transform: translate(var(--arrow-size), 50%) scale(0.5);
}

[data-tooltip-location="right"]:hover::before {
  transform: translate(var(--arrow-size), 50%) scale(1);
}

[data-tooltip-location="right"]::after {
  border-width: var(--arrow-size) var(--arrow-size) var(--arrow-size) 0;
  border-color: transparent rgba(55 64 70 / 90%) transparent transparent;
  transform-origin: right;
  transform: translateY(50%) scaleX(0);
}

[data-tooltip-location="right"]:hover::after {
  transform: translateY(50%) scaleX(1);
}

/* BOTTOM */
[data-tooltip-location="bottom"]::before,
[data-tooltip-location="bottom"]::after {
  top: calc(100% + var(--arrow-size));
  bottom: auto;
}

[data-tooltip-location="bottom"]::before {
  transform: translate(-50%, var(--arrow-size)) scale(0.5);
}

[data-tooltip-location="bottom"]:hover::before {
  transform: translate(-50%, var(--arrow-size)) scale(1);
}

[data-tooltip-location="bottom"]::after {
  border-width: 0 var(--arrow-size) var(--arrow-size) var(--arrow-size);
  border-color: transparent transparent rgba(55 64 70 / 90%) transparent;
  transform-origin: bottom;
}

/* Thumbnail settings */
@media (max-width: 750px) {
  [data-tooltip]::after {
    bottom: calc(100% + 3px);
    border-width: 7px 7px 0;
  }
}
