.breadcrumbs {
  margin: 25px 0;
  font-size: 12pt;

  ul {
    display: flex;
    gap: 10px;
    list-style: none;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      color: $blue-moyen;

      a {
        font-weight: bold;
        text-decoration: none;
        color: $blue-moyen;
      }

      &:not(:last-child)::after {
        content: ">";
        display: inline-flex;
        margin-left: 10px;
        font-weight: bold;
        font-size: 13pt;
        color: $gris-moyen;
      }
    }
  }
}
