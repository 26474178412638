.pagination-wrap {
    gap: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding:5rem 0; 
    .pagination-next-prev {
    
      font-size: 20px;
      .bi-chevron-right {
        color: #000;
        -webkit-text-stroke: 1px;
      }
      .bi-chevron-left {
        -webkit-text-stroke: 1px;
      }
      transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
      &:hover {
        transform: scale(1.1);
        color: $grey-light-2;
      }
    }
  
    .pagination-numbers {
      gap: 10px;
    }
  }

  .pagination-next, .pagination-prev {
    color: #000;
  }

  .hide-arrow {
    opacity: 0;
    pointer-events: none;
  }