
.dropdown {
   
    position: relative;
    background: transparent;
    text-align: left;

    &.white {
      margin-bottom: 16px;
    }
    .dropdown-list {
      padding: 4px;
      border: 1px solid #AFB1B6;
      background: #fff;
      color: #000;
      position: absolute;
      top: 55px;
      left: 2px;
      right: 2px;
      z-index: 2;
      transform-origin: 50% 0;
      transform: scale(1, 0);
      transition: transform .15s ease-in-out .15s;
      max-height: 145px;
      overflow-y: scroll;

      & input[type='checkbox'] {
        accent-color: #4e4e4e;
        height: 16px;
        width: 16px;
        margin: 0;
      }

  
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #000 !important; 
        border-radius: 5px;
        padding: 0 2px;
      }

      &::-webkit-scrollbar {
        width: 9px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #b3b2b2; 
        border-radius: 10px;

      }

    }
    
    .dropdown-option {
      display: flex;
      font-size: 16px;
      padding: 10px;
      align-items: center;
      justify-content: space-between;
      opacity: 0;
      transition: opacity .15s ease-in-out;
      border-radius: 8px;
      line-height: 24px;
      &:hover {
        background:#F5F5F5;
      }
    }
    
    .dropdown-label {
      background: transparent;
      color: #F1F1F1;
      display: block;
      border: 1px solid #D0D5DD;
      font-size: 16px;
      padding: 14px 1rem;
      line-height: 1;
      cursor: pointer;
      position: relative;

      &.white {
        &:before {
          filter: brightness(0.5);
        }
      }
      
      &:before {
        content: '';
        background-image: url("../../../apps/front/static/img/icons/Icon-down-white.svg");
        background-repeat: no-repeat;
        position: absolute;
        right: 17px;
        width: 18px;
        height: 18px;
      }
    }
    
    &.on {
     .dropdown-list {
        transform: scale(1, 1);
        transition-delay: 0s;
        
        .dropdown-option {
          opacity: 1;
          transition-delay: .2s;

        }
      }
      
      .dropdown-label:before {
        transform: rotate(-180deg);
      }
    }
    
    [type="checkbox"] {
      position: relative;
      top: -1px;
      margin-right: 4px;
    }
  }



.shopify-buy-frame--toggle:not(.is-sticky) {
 padding: 0 !important;
}

 