.featured-blogs-block {
  .featured-blogs {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .featured-blogs-img-container {
      height: 300px;
      background-color: #f1f1f1;
      background-image: url("../../../apps/front/static/img/icons/no-image-icon.svg");
      background-repeat: no-repeat;
      background-position: center;
      .featured-blogs-category-btn {
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 11px 15px;
        top: 20px;
        right: 24px;
        color: #616161;
        position: absolute;
        background: #ffffff;
        border: initial;
        border-radius: 21px;
      }
      .featured-blogs-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .featured-blogs-text-container {
      border-top: 1px solid #ccced5;
      padding-right: 50px;
      .featured-blogs-date {
        font-size: 14px;
        line-height: 16px;
        color: #61646b;
      }
      .featured-blogs-title {
        color: #000;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        margin-top: 24px;
      }
      .featured-blogs-title-underline {
        display: inline-block;
        width: 85px;
        border-top: 3px solid #000000;
        margin-top: 16px;
      }
      .featured-blogs-excerpt {
        margin-top: 24px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #0f443a;
      }
      .featured-blogs-link {
        display: flex;
        align-items: center;
        margin-top: 16px;
        text-transform: uppercase;
        color: #0f443a;
        .bi-arrow-right-short {
          font-size: 30px;
        }
      }
    }
  }
  .featured-blogs-view-all-link {
    display: inline-block;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #f96c48;
    margin-top: 8px;
  }
}
