.home-title {
  font-family: "Geologica", sans-serif;
  line-height: 10rem;
  font-size: 10rem;
  font-weight: 900;
  max-width: 465px;
}

.heading-primary {
  font-size: 3rem;
  line-height: 4.5rem;
  padding: 4rem 0rem;
}

.heading-secondary {
  font-size: 5rem;
  line-height: 6rem;
  font-weight: 900;
}

.heading-tertiary {
  font-family: "Inter", sans-serif;
  font-size: 1.8rem;
  font-weight: 600;
}

.heading-quaternary {
  font-family: "Inter", sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
}

.paragraph {
  font-family: "Inter", sans-serif;
  font-size: 1.3rem;
  line-height: 2.4rem;
  padding-bottom: 0.5px;
}

.paragraph-regular {
  font-size: 1.2rem;
  font-family: "Roboto", sans-serif;
}

.sub-title {
  font-size: 2rem;
  // font-family: "Poppins", sans-serif;
}

.paragraph-500 {
  font-family: "Inter", sans-serif;
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: 500;
}

.title-text-block {
  font-family: Inter , 'sans-serif';
  .title-text-block-heading {
     & p {
      line-height: 32px;
     }
  }
}

.rich-text-container {
  & p{
    margin-bottom: 15px;
  }
  & ul {
    margin-bottom: 15px;
  }
}

body p:empty {
   margin-bottom: 1rem;
  display: flex; 
}