.checkout-information-container {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  width: 100%;
  border: 1px solid #D0D5DD;
  input[type='radio'] {
    accent-color: #232323;
  }
  input[type='submit'] {
    max-width: 360px;
    align-self: center;
  }
  .form-check-label {
    font-family: Inter, serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #101928;
  }
  max-width: 100%;
  width: 100%;
  .address-information-input {
    width: 100%;
    label {
      font-family: Inter;
      color: #344054;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    input, select {
      width: 100%;
      height: 56px;
      border: 1px solid #D0D5DD;
      border-radius: 6px;
      color: #344054;
      font-family: Roboto;
      font-weight: 400;
      font-size: 14px;
      line-height: 20.3px;
      padding: 0 16px;
    }

  }
  &__header {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    &__title {
      color: #101928;
      font-feature-settings: 'cv04' on, 'cv03' on, 'cv01' on;
      font-family: Inter, serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 28.8px */
      letter-spacing: -0.48px;
    }
    &__actions {
      display: flex;
      align-items: center;
      gap: 8px;
      color: #101928;
      font-family: Roboto, serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 145%;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  &__information {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    &__phone {
      display: flex;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      color: #667185;
      font-feature-settings: 'cv04' on, 'cv03' on;
      font-family: Inter, serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 145%;
      img {
        width: 20px;
        height: 20px;
      }

    }
    &__email {
      display: flex;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      color: #667185;
      font-feature-settings: 'cv04' on, 'cv03' on;
      font-family: Inter, serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 145%;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.pac-item {
  font-family: Inter, serif;
  cursor: pointer!important;
  padding: 8px 15px!important;
}